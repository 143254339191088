import AppHeader from "./dash/AppHeader"
import { useRef, useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from '../features/auth/authSlice'
import { useLoginMutation } from '../features/auth/authApiSlice'
// import { useHasloggedinQuery, useLoginMutation } from '../features/auth/authApiSlice'
import usePersist from '../hooks/usePersist'
import useTitle from '../hooks/useTitle'
import LoaderSpinner from "./dash/common/LoaderSpinner"
import Copyright from "./dash/Copyright"

const Login = () => {
    useTitle('ServiceHero')

    const userRef = useRef()
    const errRef = useRef()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [persist, setPersist] = usePersist()

    const navigate = useNavigate()
    const dispatch = useDispatch()


    const [login, { isLoading }] = useLoginMutation()
    const cl_loggedin = localStorage.getItem("loggedin") === 'true' || false
    // const sr_loggedin = useHasloggedinQuery()
    useEffect(() => {
        if (cl_loggedin === true) {
            navigate('/home')
        }
    }, [cl_loggedin, navigate])

    useEffect(() => {
        if (userRef?.current) {
            userRef.current.focus()
        }
    }, [userRef])

    useEffect(() => {
        setErrMsg('');
    }, [username, password])

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { accessToken } = await login({ username, password }).unwrap()
            localStorage.setItem("loggedin", true)
            dispatch(setCredentials({ accessToken }))
            setUsername('')
            setPassword('')
            navigate('/home')
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(err.data?.message);
            }
            errRef.current.focus();
        }
    }

    const handleUserInput = useCallback((e) => { setUsername(e.target.value) }, [setUsername]);
    const handlePwdInput = useCallback((e) => { setPassword(e.target.value) }, [setPassword]);
    const handleToggle = useCallback(() => { setPersist(prev => !prev) }, [setPersist]);

    const errClass = errMsg ? "D_B_error" : "offscreen"
    let content = null
    if (isLoading) {
        content = <LoaderSpinner />
    } else {
        content = (
            <>
                <AppHeader />
                <div className="flex_main">
                    {/* <div className="flex_main_left"></div> */}
                    <form onSubmit={handleSubmit}>
                        <div className="login_box">
                            <p ref={errRef} className={errClass} aria-live="assertive">{errMsg}</p>
                            <label htmlFor="username" className="hide_element">User Name</label>
                            <input
                                className="login_input"
                                type="text"
                                id="username"
                                ref={userRef}
                                value={username}
                                placeholder="User Name..."
                                onChange={handleUserInput}
                                autoComplete="off"
                                required
                            />
                            <label htmlFor="password" className="hide_element">Pasword</label>
                            <input
                                type="password"
                                className="login_input"
                                id="password"
                                onChange={handlePwdInput}
                                placeholder="Password..."
                                value={password}
                                required
                            />
                            <button className="login_button" type="submit">Sign In</button>
                            <label htmlFor="persist" className="login_persist">
                                <input
                                    type="checkbox"
                                    className="form__checkbox"
                                    id="persist"
                                    onChange={handleToggle}
                                    checked={persist}
                                />
                                Trust This Device
                            </label>
                        </div>
                    </form>
                    <div style={{ position: 'absolute', bottom: '0.5rem', left: '0.5rem' }}><Copyright /></div>
                </div>
            </>
        )
    }
    return content
}
export default Login

