import React from 'react'
import { MdClear } from 'react-icons/md'
const SearchRulesView = ({ rules, setRules }) => {
    return (rules?.length > 0 &&
        <div className='searcher_rules_view'>
            {rules.map((rule, index) => (
                <div key={index} className='searcher_rules_item'>
                    <div className='searcher_rules_item_del'
                        onClick={() => {
                            setRules(lrules => lrules.filter((rul, idx) => index !== idx))
                        }}><MdClear /></div>
                    <div style={{ paddingLeft: '1.5rem' }}>{`${rule?.name} ${rule.compare} ${rule.value1} ${rule.value1 && rule.value2 ? ` and ${rule.value2}` : rule.value2}`}</div>
                </div>
            ))}
        </div>
    )
}

export default SearchRulesView