import { createContext, useContext, useState } from "react";
import { useAddNewUserMutation, useDeleteUserMutation, useUpdateUserMutation } from "../../../features/usersApiSlice";
import GC from "../context/GC";
import useAuthorize from "../../../hooks/useAuthorize";
import Searcher from "../common/Searcher/Searcher";
import EditTableColumns from "../common/EditTableColumns/EditTableColumns";

export class TUser {
    constructor(user) {
        this._id = user ? user._id : ''
        this.id = user ? user._id : ''
        this.username = user ? user.username : ''
        this.password = user ? user.password : ''
        this.active = user ? user.active : true
        this.isAdmin = user ? user.isAdmin : false
        this.facilities = user ? user?.facilities : []
        this.joiningdate = user?.period?.length > 0 ? user?.period[0]?.from ?? '' : ''
        this.period = user ? user?.period : [{ from: '', to: '' }]
        this.mobile = user ? user.mobile : ''
        this.email = user ? user.email : ''
        this.assign = user ? user.assign : ''
        this.assignRevert = user ? user.assignRevert : ''
        this.reassignAssigned = user ? user.reassignAssigned : ''
        this.reassignAttended = user ? user.reassignAttended : ''
        this.reassignDisposed = user ? user.reassignDisposed : ''
        this.attend = user ? user.attend : ''
        this.attendRevert = user ? user.attendRevert : ''
        this.disposeCreated = user ? user.disposeCreated : ''
        this.disposeAssigned = user ? user.disposeAssigned : ''
        this.disposeAttended = user ? user.disposeAttended : ''
        this.disposeRevert = user ? user.disposeRevert : ''
        this.renewal = user ? user.renewal : false
    }
}

const TCreator = () => {
    const [addNewUser, { isError, isLoading, isSuccess, isUninitialized, error }] = useAddNewUserMutation()
    return { addNewUser, isError, isLoading, isSuccess, isUninitialized, error }
}
const TDeleter = () => {
    const [deleteUser, { isError, isLoading, isSuccess, isUninitialized, error }] = useDeleteUserMutation()
    return { deleteUser, isError, isLoading, isSuccess, isUninitialized, error }
}
const TUpdater = () => {
    const [updateUser, { isError, isLoading, isSuccess, isUninitialized, error }] = useUpdateUserMutation()
    return { updateUser, isError, isLoading, isSuccess, isUninitialized, error }
}

const UContext = createContext()

export const UCProvider = ({ children }) => {
    const [user, setUser] = useState(new TUser())
    const [userID, setUserID] = useState('')
    const [username, setUsername] = useState('')
    const updater = TUpdater()
    const deleter = TDeleter()
    const creator = TCreator()
    const { FL, thisuser, userconfig } = useContext(GC);
    const allow = {
        create: useAuthorize([FL?.USER_CREATE]),
        read: useAuthorize([FL?.USER_READ]),
        delete: useAuthorize([FL?.USER_DELETE]),
        edit: useAuthorize([FL?.USER_EDIT]),
    }
    const fieldsarr = [
        { name: 'Name', method: ({ username }) => username },
        { name: 'Status', method: ({ active }) => active ? 'Active' : 'Disabled', options: [{ value: 'Active', label: 'Active' }, { value: 'Disabled', label: 'Disabled' }], compare: [['equals']] },
        { name: 'Mobile', method: ({ mobile }) => mobile },
        { name: 'Email', method: ({ email }) => email },
        { name: 'Type', method: ({ isAdmin }) => isAdmin ? 'Admin' : 'User' },
        { name: 'Joining Date', method: ({ period }) => period.map(per => per.from), type: 'date' },
    ]

    const searcher = Searcher({
        fieldsarr
    });

    const get_fields = () => {
        let fields = {}
        fieldsarr.map(lfld => {
            fields[lfld.name] = lfld
            return true
        })
        return fields
    }
    const fields = get_fields()

    const tableediter = EditTableColumns(
        {
            fieldsarr: [...fieldsarr],
            selected: thisuser?.colsuserreport ? thisuser.colsuserreport : [],
            saveselected: async function (selected) {
                if (userconfig[0]) {
                    await userconfig[0]({ colsuserreport: [...selected] })
                }
            }
        }
    )


    return (
        <UContext.Provider value={{
            allow,
            user, setUser,
            userID, setUserID, username, setUsername,
            updater, deleter, creator,
            searcher, fieldsarr, fields, tableediter,
        }}>
            {children}
        </UContext.Provider>
    )

}


export default UContext
