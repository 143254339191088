import { useAddNewCallMutation, useAssignCallMutation, useAttendCallMutation, useDeleteCallMutation, useDisposeCallMutation, useGetNextReferenceQuery, useRevertCallMutation, useUpdateCallMutation } from "../../../features/serviceCallApiSlice"

class TProduct {
    constructor() {
        this.id = ''
        this.name = ''
        this.active = ''
        this.product = ''
        this.serialnumber = ''
        this.version = ''
        this.date = ''
        this.email = ''
        this.password = ''
        this.TSSExpiry = ''
        this.TDL = ''
        this.eInvoice = ''
        this.eWayBill = ''
        this.freecalls = 0
        this.callsused = 0
        this.balance = 0
    }
    load(prod) {
        if (prod?._id) {
            this.id = prod._id
            this.name = prod.name
            this.active = prod.active
            this.product = prod.product
            this.serialnumber = prod.serialnumber
            this.version = prod.version
            this.date = prod.date
            this.email = prod.email
            this.password = prod.password
            this.TSSExpiry = prod.TSSExpiry
            this.TDL = prod.TDL
            this.eInvoice = prod.eInvoice
            this.eWayBill = prod.eWayBill
            this.freecalls = prod.freecalls
            this.callsused = prod.callsused ? prod.callsused : 0
            this.balance = this.freecalls - (this.callsused > 0 ? this.callsused : 0)
        }
    }
}

class TAMC {
    constructor() {
        this.id = ''
        this.from = ''
        this.to = ''
        this.amount = 0
        this.calls = 0
        this.callsused = 0
        this.balance = 0
    }
    load(amc) {
        if (amc?._id) {
            this.id = amc.id
            this.from = amc.from
            this.to = amc.to
            this.amount = amc.amount
            this.calls = amc.calls
            this.callsused = amc.callsused ? amc.callsused : 0
            this.balance = this.calls - (this.callsused > 0 ? this.callsused : 0)
        }
    }
}

class TCall {
    constructor(call) {
        this.id = ''
        this._id = ''
        this.date = new Date()
        this.customer = ''
        this.customername = ''
        this.productdet = new TProduct()
        this.product = ''
        this.AMCDet = new TAMC()
        this.AMC = ''
        this.callType = ''
        this.chargeType = ''
        this.location = ''
        this.serialnumber = ''
        this.problem_description = ''
        this.assigned_time = ''
        this.appointment_time = ''
        this.attended_from = ''
        this.attended_to = ''
        this.createdby = ''
        this.assignedby = ''
        this.assignedto = ''
        this.attendedby = ''
        this.open = true
        this.attended_remarks = ''
        this.reference = ''
        this.createdAt = ''
        this.disposedby = ''
        this.disposed_time = ''
        if (call) this.load(call)
    }
    load(call) {
        if (call?.id) {
            this.id = call.id
            this._id = call.id
            this.date = call.date
            this.customer = call.customer
            this.customername = call.customername
            this.product = call.product
            this.productdet.load(call.productdet)
            this.AMC = call.AMC
            this.AMCDet.load(call.AMCDet)
            this.callType = call.callType
            this.chargeType = call.chargeType
            this.location = call.location
            this.serialnumber = call.serialnumber
            this.problem_description = call.problem_description
            this.assigned_time = call.assigned_time
            this.appointment_time = call.appointment_time
            this.attended_from = call.attended_from
            this.attended_to = call.attended_to
            this.createdby = call.createdby
            this.assignedby = call.assignedby
            this.assignedto = call.assignedto
            this.attendedby = call.attendedby
            this.open = call.open
            this.attended_remarks = call.attended_remarks
            this.reference = call.reference
            this.createdAt = call.createdAt
            this.disposedby = call.disposedby
            this.disposed_time = call.disposed_time
        }
    }
}

const TUpdater = () => {
    const [updateCall, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateCallMutation();
    return { updateCall, isLoading, isSuccess, isError, error }

}
const TAssigner = () => {
    const [assignCall, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAssignCallMutation();
    return { assignCall, isLoading, isSuccess, isError, error }

}
const TAttender = () => {
    const [attendCall, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAttendCallMutation();
    return { attendCall, isLoading, isSuccess, isError, error }

}
const TDisposer = () => {
    const [disposeCall, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useDisposeCallMutation();
    return { disposeCall, isLoading, isSuccess, isError, error }

}
const TReverter = () => {
    const [revertCall, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRevertCallMutation();
    return { revertCall, isLoading, isSuccess, isError, error }

}
const TCreator = () => {
    const [addNewCall, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewCallMutation();
    return { addNewCall, isLoading, isSuccess, isError, error }
}

const TDeleter = () => {
    const [deleteCall, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useDeleteCallMutation();
    return { deleteCall, isLoading, isSuccess, isError, error }
}
const TNextRef = (date) => {
    return useGetNextReferenceQuery({ date }, {
        refetchOnMountOrArgChange: true
    })
}


export { TProduct, TAMC, TCall, TUpdater, TCreator, TDeleter, TAssigner, TAttender, TDisposer, TReverter, TNextRef };
