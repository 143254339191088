import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../app/api/apiSlice"

const customersAdapter = createEntityAdapter({})

const initialState = customersAdapter.getInitialState()

export const customersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCustomers: builder.query({
            /* https://stackoverflow.com/questions/76350130/how-can-i-access-my-store-from-rtk-query-endpoints */
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                const state = api.getState()
                const xData = state.api.queries[`getCustomers("${arg}")`].data
                const xFetchTime = xData?.fetchtime
                try {
                    const { data } = await baseQuery({
                        url: '/customer',
                        params: { time: xFetchTime },
                        validateStatus: (response, result) => {
                            return response.status === 200 && !result.isError
                        },
                    })
                    let ids = []
                    let entities = {}
                    if (xData?.ids?.length > 0) {
                        xData.ids.map(lid => {
                            if ((!data || !data?.deleted || data?.deleted?.length === 0 || !data.deleted.includes(lid)) && xData.entities[lid]) {
                                ids.push(lid)
                                entities[lid] = xData.entities[lid]
                            }
                            return false
                        })
                    }
                    data?.docs?.map(doc => {
                        if (!ids.includes(doc._id)) ids.push(doc._id)
                        entities[doc._id] = ({ id: doc._id, ...doc })
                        return false
                    })
                    const fetchtime = data?.fetchtime ? data?.fetchtime : xFetchTime ? xFetchTime : ''
                    return ({ data: { ids, entities, fetchtime } })
                } catch (error) {
                    return { error }
                }
            },
            /* query: () => ({
                url: '/customer',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }), */
            transformResponse: responseData => {
                const loadedCustomers = responseData.map(customer => {
                    customer.id = customer._id
                    return customer
                });
                return customersAdapter.setAll(initialState, loadedCustomers)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Customer', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Customer', id }))
                    ]
                } else return [{ type: 'Customer', id: 'LIST' }]
            }
        }),
        addNewCustomer: builder.mutation({
            query: initialCustomerData => ({
                url: '/customer',
                method: 'POST',
                body: initialCustomerData
            }),
            invalidatesTags: [
                { type: 'Customer', id: "LIST" }
            ]
        }),
        updateCustomer: builder.mutation({
            query: initialCustomerData => ({
                url: '/customer',
                method: 'PATCH',
                body: initialCustomerData
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Customer', id: arg.id }
            ]
        }),
        deleteCustomer: builder.mutation({
            query: (initialCustomerData) => ({
                url: `/customer`,
                method: 'DELETE',
                body: initialCustomerData
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Customer', id: arg.id }
            ]
        }),
        isDuplicate: builder.mutation({
            query: initialCustomerData => ({
                url: '/customer/isduplicate',
                method: 'POST',
                body: initialCustomerData
            }),
        })
    }),
})

export const {
    useGetCustomersQuery,
    useAddNewCustomerMutation,
    useUpdateCustomerMutation,
    useDeleteCustomerMutation,
    useIsDuplicateMutation
} = customersApiSlice

// returns the query result object
export const selectCustomersResult = customersApiSlice.endpoints.getCustomers.select()

// creates memoized selector
const selectCustomersData = createSelector(
    selectCustomersResult,
    customersResult => customersResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCustomers,
    selectById: selectCustomerById,
    selectIds: selectCustomerIds
    // Pass in a selector that returns the customers slice of state
} = customersAdapter.getSelectors(state => selectCustomersData(state) ?? initialState)