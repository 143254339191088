import React, { useEffect, useRef, useState } from 'react'
import './EditTableColumns.css'

const EditTableColumns = ({ fieldsarr = [], selected = [], saveselected }) => {
  const [show, setshow] = useState(false)
  const edittablecolumnsdiv = useRef(null)
  const [leftsel, setleftsel] = useState([])
  const [rightsel, setrightsel] = useState([])
  const [_selected, set_selected] = useState([])
  const isloaded = useRef(false)
  useEffect(() => {
    if (!isloaded.current && _selected?.length === 0 && selected?.length > 0) {
      isloaded.current = true
      set_selected([...selected])
    }
  }, [set_selected, _selected, selected, isloaded])
  useEffect(() => {
    if (show && edittablecolumnsdiv?.current)
      edittablecolumnsdiv.current.focus()
  }, [show, edittablecolumnsdiv])
  let content = null
  if (show) {
    content = <div style={{ position: 'relative' }}>
      <div
        ref={edittablecolumnsdiv}
        style={{
          border: '1px solid #ddd',
          borderRadius: '5px',
          zIndex: '99',
          position: 'absolute',
          backgroundColor: '#fff',
          top: '0',
          outline: 'none',
          padding: '10px',
          boxShadow: '0 0 5px 0 rgba(199, 46, 46, 0.4)',
        }}
        tabIndex={2}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)){
            setshow(false)
            set_selected([...selected])
          }
        }}
        onKeyDown={(e) => {
          e.stopPropagation()
          if (e.key === 'Escape'){
            setshow(false)
            set_selected([...selected])
          }
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: '5px' }}><span style={{ borderBottom: '1px solid' }}>Select Columns</span></div>
        <div
          outline='none'
          style={{
            display: 'grid',
            gridTemplateColumns: '10rem 3rem 10rem',
          }}
        >
          {/* Left Selection */}
          <div>
            <select multiple={true}
              onChange={(e) => {
                setleftsel(Array.from(e.target.selectedOptions, lopt => lopt.value))
              }}
              value={leftsel}
              style={{ overflow: 'auto', outline: 'none', border: 'none', width: '100%', minHeight: '30vh' }}
            >
              {fieldsarr.filter(llab => (!_selected.includes(llab.name))).map((llab, index) => (
                <option key={index} value={llab.name}
                  style={{ width: '100%', padding: '5px' }}
                >
                  {`${index + 1}. ${llab.name}`}
                </option>
              ))}
            </select>
          </div>
          {/* Move Buttons */}
          <div style={{ display: 'flex', placeItems: 'center', flexDirection: 'column' }}>
            <div className='edittablecolumns_movebuttons'
              onClick={() => {
                set_selected([..._selected, ...leftsel])
                setleftsel([])
                setrightsel([])
              }}
            >&gt;</div>
            <div className='edittablecolumns_movebuttons'
              onClick={() => {
                set_selected(_selected.filter(lval => !rightsel.includes(lval)))
                setleftsel([])
                setrightsel([])
              }}
            >&lt;</div>
          </div>
          {/* Right Selection */}
          <div>
            <select multiple={true}
              onChange={(e) => {
                setrightsel(Array.from(e.target.selectedOptions, lopt => lopt.value))
              }}
              value={rightsel}
              style={{ overflow: 'auto', outline: 'none', border: 'none', width: '100%', minHeight: '30vh' }}
            >
              {_selected?.map((llab, index) => (
                <option key={index} value={llab}
                  style={{ width: '100%', padding: '5px' }}
                >
                  {`${index + 1}. ${llab}`}
                </option>
              ))}
            </select>
          </div>
        </div >
        {/* Save/Cancel Buttons */}
        <div style={{ display: 'flex', paddingTop: '5px', alignItems: 'center', justifyContent: 'space-around', width: '100%' }}>
          <div className='edittablecolumns_movebuttons' style={{ maxWidth: '5rem' }}
            onClick={async () => {
              if (saveselected) {
                await saveselected([..._selected])
              }
              setleftsel([])
              setrightsel([])
              setshow(false)
            }}
          >Save</div>
          <div className='edittablecolumns_movebuttons' style={{ maxWidth: '5rem' }}
            onClick={() => {
              set_selected([..._selected])
              setleftsel([])
              setrightsel([])
              setshow(false)
              set_selected([...selected])
            }}
          >Cancel</div>
        </div>
      </div>
    </div>
  }
  return { setshow, content }

}

export default EditTableColumns