import React, { createContext, useContext } from "react";
import { TCreator, TDeleter, TUpdater } from "./PClass";
import useAuthorize from "../../../hooks/useAuthorize";
import Searcher from "../common/Searcher/Searcher"
import GC from "../context/GC";
import EditTableColumns from "../common/EditTableColumns/EditTableColumns";

const PContext = createContext()


export const PContextProvider = ({ children }) => {
    const updater = TUpdater()
    const deleter = TDeleter()
    const creator = TCreator()
    const { FL, thisuser, userconfig, customers } = useContext(GC);
    const allow = {
        create: useAuthorize([FL?.PRODUCT_CREATE]),
        read: useAuthorize([FL?.PRODUCT_READ]),
        delete: useAuthorize([FL?.PRODUCT_DELETE]),
        edit: useAuthorize([FL?.PRODUCT_EDIT]),
    }


    const fieldsarr = [
        { name: 'Name', method: ({ name }) => name },
        { name: 'Status', method: ({ active }) => active ? 'Active' : 'Disabled', options: [{ value: 'Active', label: 'Active' }, { value: 'Disabled', label: 'Disabled' }], compare: [['equals']] },
        { name: 'Description', method: ({ description }) => description },
        { name: 'Sale Count', method: ({ _id }) => (customers.data.ids.reduce((count, lcustid) => count + customers.data.entities[lcustid]?.products?.reduce((pcount, lprod) => pcount + (lprod.product.toString() === _id.toString() ? 1 : 0), 0) ?? 0, 0)), type: 'number', searchhide: true },
    ]

    const get_fields = () => {
        let fields = {}
        fieldsarr.map(lfld => {
            fields[lfld.name] = lfld
            return true
        })
        return fields
    }
    const fields = get_fields()

    const tableediter = EditTableColumns(
        {
            fieldsarr: [...fieldsarr],
            selected: thisuser?.colsprodreport ? thisuser.colsprodreport : [],
            saveselected: async function (selected) {
                if (userconfig[0]) {
                    await userconfig[0]({ colsprodreport: [...selected] })
                }
            }
        }
    )
    const searcher = Searcher({
        fieldsarr
    })



    return (
        <PContext.Provider value={{
            allow,
            updater, deleter, creator,
            fields, fieldsarr, 
            searcher,
            tableediter,
        }}>
            {children}
        </PContext.Provider>
    )

}

export default PContext