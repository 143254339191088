import React from 'react'
import Select from 'react-select'

const SelectionTabValue = ({ fieldsI, entry, setEntry, addRule }) => {
    return (fieldsI?.length > 0 && entry.tab === 'value') &&
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            {(!fieldsI?.length || entry.tab !== 'value') ? null
                : entry.field.type === 'text' && entry.field?.options?.length > 0 && entry.compare?.includes('equals') ?
                    <Select
                        options={entry.field.options}
                        onChange={(val) => {
                            setEntry(entr => ({ ...entr, value1: val?.label }))
                        }}
                        value={{ value: entry?.value1 ?? '', label: entry?.value1 ?? '' }}
                        className={'D_B_InputWithLabel_select'}
                        isClearable={true}
                        menuPlacement='auto'
                        padding='0'
                        styles={{ control: (provided, state) => ({ ...provided, width: '20vw' }) }}
                    />
                    : entry.field.type === 'text' ?
                        <input
                            type='text'
                            placeholder='Find..'
                            autoFocus
                            className='searcher_value_box'
                            onChange={(e) => {
                                setEntry(entr => ({ ...entr, value1: e.target.value }))
                            }}
                        />
                        : entry.compare.includes('between') ?
                            <>
                                <input
                                    type={entry.field.type}
                                    autoFocus
                                    placeholder='Value 1'
                                    className='searcher_value_box'
                                    onChange={(e) => {
                                        setEntry(entr => ({ ...entr, value1: e.target.value }))
                                    }}
                                    style={{ width: '10vw' }}
                                />
                                <span> and </span>
                                <input
                                    type={entry.field.type}
                                    autoFocus
                                    placeholder='Value 1'
                                    className='searcher_value_box'
                                    onChange={(e) => {
                                        setEntry(entr => ({ ...entr, value2: e.target.value }))
                                    }}
                                    style={{ width: '10vw' }}
                                />
                            </>
                            :
                            <input
                                type={entry.field.type}
                                autoFocus
                                tabIndex={2}
                                placeholder='Find'
                                className='searcher_value_box'
                                onChange={(e) => {
                                    setEntry(entr => ({ ...entr, value1: e.target.value }))
                                }}
                            />
            }
            <span></span>
            <button style={{ padding: '0.5rem 2rem', border: 'none', outline: 'none', borderRadius: '5px' }}
                onClick={addRule}
            >add</button>
        </div>

}

export default SelectionTabValue