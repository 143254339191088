import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Login from "./components/Login";
import DashLayout from "./components/dash/DashLayout";
// import DashHome from "./components/dash/dashboard/DashHome";
import PersistLogin from "./features/auth/PersistLogin"
import Prefetch from "./features/auth/Prefetch";
import PrefetchFacilities from "./features/auth/PrefetchFacilities";
import RequireAuth from "./features/auth/RequireAuth";

// import Users from "./components/dash/users/Users";
// import Products from "./components/dash/products/Products";
// import Customers from "./components/dash/customers/Customers";
// import ServiceCall from "./components/dash/servicecall/ServiceCall";
// import UserProfile from "./components/userprofile/UserProfile";
import { SCContextProvider } from "./components/dash/servicecall/Context";
import { CContextProvider } from "./components/dash/customers/CContext";
import { GCProvider } from "./components/dash/context/GC";
import { UCProvider } from "./components/dash/users/UserContext";
import { PContextProvider } from "./components/dash/products/PContext";
// import Renewal from "./components/dash/renewal/Renewal";
import { Suspense, lazy } from "react";
import LoaderSpinner from "./components/dash/common/LoaderSpinner";

const DashHome = lazy(() => import("./components/dash/dashboard/DashHome"))
const Renewal = lazy(() => import("./components/dash/renewal/Renewal"))
const Users = lazy(() => import("./components/dash/users/Users"))
const Products = lazy(() => import("./components/dash/products/Products"))
const Customers = lazy(() => import("./components/dash/customers/Customers"))
const ServiceCall = lazy(() => import("./components/dash/servicecall/ServiceCall"))
const UserProfile = lazy(() => import("./components/userprofile/UserProfile"))
function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Login />} />

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<PrefetchFacilities />}>
            <Route element={<RequireAuth />}>
              <Route element={<Prefetch />}>
                <Route element={<GCProvider />} >{/* General Context */}
                  <Route path="home" element={<DashLayout />}>
                    <Route index element={
                      <Suspense fallback={<LoaderSpinner />}>
                        <DashHome />
                      </Suspense>
                    } />
                    <Route path="renewal" element={
                      <Suspense fallback={<LoaderSpinner />}>
                        <CContextProvider><Renewal /></CContextProvider>
                      </Suspense>
                    } />
                    <Route path="users" element={
                      <Suspense fallback={<LoaderSpinner />}>
                        <UCProvider> <Users /></UCProvider>
                      </Suspense>
                    } />
                    <Route path="products" element={
                      <Suspense fallback={<LoaderSpinner />}>
                        <PContextProvider><Products /></PContextProvider>
                      </Suspense>
                    } />
                    <Route path="customers" element={
                      <Suspense fallback={<LoaderSpinner />}>
                        <CContextProvider><Customers /></CContextProvider>
                      </Suspense>
                    } />
                    <Route path="servicecall" element={
                      <Suspense fallback={<LoaderSpinner />}>
                        <SCContextProvider> <ServiceCall /></SCContextProvider>
                      </Suspense>
                    } />
                    <Route path="profile" element={
                      <Suspense fallback={<LoaderSpinner />}>
                        <UCProvider><UserProfile /></UCProvider>
                      </Suspense>
                    } />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

      </Route>
    </Routes>
  );
}

export default App;
