
const day_end = (date) => {
    return new Date(new Date(date && (date instanceof Date && date.getFullYear()) ? date : new Date()).setHours(23, 59, 59, 999))
}

const day_start = (date) => {
    return new Date(new Date(date ? date : new Date()).setHours(0, 0, 0, 0))
}

const week_start = (date) => {
    const ldate = date ? date : new Date()
    return new Date(ldate.setDate(ldate.getDate() - ldate.getDay()))
}
const week_end = (date) => {
    const ldate = date ? date : new Date()
    return new Date(ldate.setDate(ldate.getDate() - ldate.getDay() + 6))
}

const month_arith = (date, num) => {
    if (!date || !(date instanceof Date && date.getFullYear())) {
        return ''
    } else if (!num || typeof num !== 'number') {
        return date
    } else {
        let ldate = month_start(date)
        ldate.setMonth(ldate.getMonth() + num)
        ldate = month_end(ldate)
        if (ldate.getDate() > date.getDate())
            ldate.setDate(date.getDate())
        return new Date(ldate)
    }
}

const utcToLocal = (date) => {
    if (date && (date instanceof Date && date.getFullYear())) {
        date.setUTCMinutes(date.getUTCMinutes() - date.getTimezoneOffset())
        return date
    }
    return null
}
const localToUTC = (date) => {
    if (date && (date instanceof Date && date.getFullYear())) {
        date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
        return date
    }
    return null
}

const month_end = (date) => {
    const ldate = date && (date instanceof Date && date.getFullYear()) ? date : new Date()
    ldate.setDate(1)
    ldate.setMonth(ldate.getMonth() + 1)
    ldate.setDate(ldate.getDate() - 1)
    return day_end(ldate)
}

const month_start = (date) => {
    const ldate = date && (date instanceof Date && date.getFullYear()) ? date : new Date()
    ldate.setDate(1)
    return day_start(ldate)
}


const year_end = (date) => {
    const ldate = date && (date instanceof Date && date.getFullYear()) ? date : new Date()
    ldate.setMonth(11)
    ldate.setDate(31)
    return day_end(ldate)
}
const year_start = (date) => {
    const ldate = date && (date instanceof Date && date.getFullYear()) ? date : new Date()
    ldate.setMonth(0)
    ldate.setDate(1)
    return day_start(ldate)
}


const datetime_local = (date) => {
    return (date instanceof Date && date.getFullYear()) ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}` : ''
}
const datetime_localstr = (date) => {
    return (date instanceof Date && date.getFullYear()) ? `${date.getDate().toString().padStart(2, '0')} ${date.toLocaleDateString('en-UK', { month: 'long' }).substr(0, 3)} ${date.getFullYear()}  ${date.toLocaleTimeString()}` : ''
}
const date_localstr = (date) => {
    return (date instanceof Date && date.getFullYear()) ? `${date.getDate().toString().padStart(2, '0')} ${date.toLocaleDateString('en-UK', { month: 'long' }).substr(0, 3)} ${date.getFullYear()}` : ''
}

const dateTostr = (date, sep) => {
    return (date instanceof Date) ? `${date.getFullYear()}${sep}${(date.getMonth() + 1).toString().padStart(2, '0')}${sep}${date.getDate().toString().padStart(2, '0')}` : ''
}
const strToDate = (str) => {
    return (str) ? new Date(parseInt(str.substring(0, 4)), parseInt(str.substring(4, 6)) - 1, parseInt(str.substring(6, 8))) : str
}

const date_weekstr = (date) => {
    const ldate0 = new Date(date).setDate(date.getDate() - date.getDay())
    const ldate1 = new Date(date).setDate(date.getDate() - date.getDay() + 6)
    return (date instanceof Date && date.getFullYear()) ? `${date_localstr(new Date(ldate0))} - ${date_localstr(new Date(ldate1))}` : ''
}
const date_monthstr = (date) => {
    return (date instanceof Date && date.getFullYear()) ? `${date.toLocaleDateString('en-UK', { month: 'long' }).substr(0, 3)} ${date.getFullYear()}` : ''
}
const date_yearstr = (date) => {
    return (date instanceof Date && date.getFullYear()) ? `${date.getFullYear()}` : ''
}


export {
    day_end, day_start, week_start, week_end, month_end, month_start, year_start, year_end,
    datetime_local, datetime_localstr, date_localstr, dateTostr, strToDate,
    utcToLocal, localToUTC,
    date_weekstr, date_monthstr, date_yearstr,
    month_arith,
};
