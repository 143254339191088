import React from 'react'

const SelectionTabCompare = ({ fieldsI, entry, setEntry, comparisons, setRules, entrytempl }) => {
    return fieldsI?.length > 0 && entry.tab === 'compare' &&
        (entry.field.compare ?? comparisons[entry.field.type] ?? comparisons['text'])?.map((combo, index) => (
            <div className='searcher_adv_compare_pick_wrap' key={index} >
                {combo.map((comp, index) => (
                    <div className='searcher_adv_compare_pick' key={index}
                        onMouseDown={() => {
                            if (comp === 'empty' || comp === 'not empty') {
                                setRules(lrules => ([...lrules, {
                                    name: entry.field.name,
                                    method: entry.field.method,
                                    compare: comp,
                                    value1: '',
                                    value2: '',
                                    type: entry.field.type,
                                }]))
                                setEntry(entrytempl)
                            } else {
                                setEntry(entr => ({ ...entr, compare: comp, tab: 'value' }))
                            }
                        }}>
                        {comp}
                    </div>
                ))}
            </div>
        ))
}



export default SelectionTabCompare