import React, { useCallback, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GC from '../../context/GC'
import { dateTostr, date_localstr, day_end, day_start, month_arith, month_end, month_start, week_end, week_start, year_end, year_start } from '../../../../utils/datefns'
import SubForm from '../../common/SubForm'
import InputWithLabel from '../../common/InputWithLabel'
import { setSCPeriod } from '../../../../features/globalSlice'
import { FaAnglesLeft, FaAnglesRight } from 'react-icons/fa6'
import LoaderSpinner from '../../common/LoaderSpinner'

const ChangePeriod = () => {
    const periodfrom = useSelector(state => state.global.servicecall.periodfrom)
    const periodto = useSelector(state => state.global.servicecall.periodto)
    const { calls } = useContext(GC)
    const scfrom = new Date(periodfrom)
    const scto = new Date(periodto)
    const [scfromstr, setscfromstr] = useState(dateTostr(scfrom, '-'))
    const [sctostr, setsctostr] = useState(dateTostr(scto, '-'))
    const [action, setaction] = useState(null)
    const [chunk, setchunk] = useState('')
    const [stage, setstage] = useState(1)
    const [styles] = useState({
        chunk: { padding: '2px 5px', minWidth: '6rem', backgroundColor: '#ddd', textAlign: 'center', borderRadius: '5px', cursor: 'pointer' },
        chunksel: { padding: '2px 5px', minWidth: '6rem', textAlign: 'center', borderRadius: '5px', cursor: 'pointer', backgroundColor: '#cdf1cfe8' },
        chunkwrap: { display: 'flex', padding: '5px', flexWrap: 'wrap', gap: '10px', justifyContent: 'space-evenly', width: '22rem' },
        button: { width: '5rem', padding: '5px', backgroundColor: '#ddd', textAlign: 'center', borderRadius: '10px', cursor: 'pointer' },
        buttonwrap: { display: 'flex', justifyContent: 'space-around', width: '70%', marginTop: '5px' }
    })

    const dispatch = useDispatch()
    const setperiodstr = useCallback((lchunk, step) => {
        if (lchunk !== chunk) setchunk(lchunk)
        if (lchunk === 'day') {
            const ldate = !step || step === 0 ? new Date() : sctostr ? new Date(sctostr) : scfromstr ? new Date(scfromstr) : new Date()
            ldate.setDate(ldate.getDate() + (step ? step : 0))
            setscfromstr(dateTostr(new Date(ldate), '-'))
            setsctostr(dateTostr(new Date(ldate), '-'))
        } else if (lchunk === 'week') {
            let ldate0 = !step || step === 0 ? week_start() : sctostr ? week_start(new Date(sctostr)) : scfromstr ? week_start(new Date(scfromstr)) : week_start(new Date())
            if (step) ldate0 = new Date(ldate0.setDate(ldate0.getDate() + (step * 7)))
            setscfromstr(dateTostr(week_start(ldate0), '-'))
            setsctostr(dateTostr(week_end(ldate0), '-'))
        } else if (lchunk === 'month') {
            let ldate0 = !step || step === 0 ? month_start() : sctostr ? month_start(new Date(sctostr)) : scfromstr ? month_start(new Date(scfromstr)) : month_start(new Date())
            if (step) ldate0 = new Date(month_arith(ldate0, step))
            setscfromstr(dateTostr(ldate0, '-'))
            setsctostr(dateTostr(month_end(ldate0), '-'))
        } else if (lchunk === 'year') {
            let ldate0 = !step || step === 0 ? year_start() : sctostr ? year_start(new Date(sctostr)) : scfromstr ? year_start(new Date(scfromstr)) : year_start(new Date())
            if (step) ldate0 = new Date(ldate0.setFullYear(ldate0.getFullYear() + step))
            setscfromstr(dateTostr(ldate0, '-'))
            setsctostr(dateTostr(year_end(ldate0), '-'))
        }

    }, [chunk, scfromstr, sctostr])
    return (<>
        {stage === 2 && <LoaderSpinner />}
        {action === 'CHANGEPERIOD' && stage === 1 &&
            < SubForm
                disposer={() => {
                    setaction('')
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'bottom',
                        backgroundColor: 'transparent',
                        placeItems: 'center',
                        padding: '10px',
                    }}
                >
                    <InputWithLabel
                        type={'date'}
                        title={'From'}
                        on_change={(e) => {
                            setscfromstr(e.target.value)
                        }}
                        value={scfromstr}
                        input_style={{ width: '9rem' }}
                        label_style={{ width: '9rem' }}
                        label_width='9rem'
                    />
                    <InputWithLabel
                        type={'date'}
                        title={'To'}
                        on_change={(e) => {
                            setsctostr(e.target.value)
                        }}
                        value={sctostr}
                        input_style={{ width: '9rem' }}
                        label_style={{ width: '9rem' }}
                        label_width='9rem'
                    />
                    <div style={styles.chunkwrap}>
                        <div style={chunk === 'day' ? styles.chunksel : styles.chunk} onClick={(e) => { e.stopPropagation(); setperiodstr('day', 0); }}>
                            {chunk === 'day' && <FaAnglesLeft onClick={(e) => { e.stopPropagation(); setperiodstr('day', -1); }} />}&nbsp;Day&nbsp;
                            {chunk === 'day' && <FaAnglesRight onClick={(e) => { e.stopPropagation(); setperiodstr('day', 1); }} />}
                        </div>
                        <div style={chunk === 'week' ? styles.chunksel : styles.chunk} onClick={(e) => { e.stopPropagation(); setperiodstr('week', 0); }}>
                            {chunk === 'week' && <FaAnglesLeft onClick={(e) => { e.stopPropagation(); setperiodstr('week', -1); }} />}&nbsp;Week&nbsp;
                            {chunk === 'week' && <FaAnglesRight onClick={(e) => { e.stopPropagation(); setperiodstr('week', 1); }} />}
                        </div>
                        <div style={chunk === 'month' ? styles.chunksel : styles.chunk} onClick={(e) => { e.stopPropagation(); setperiodstr('month', 0); }}>
                            {chunk === 'month' && <FaAnglesLeft onClick={(e) => { e.stopPropagation(); setperiodstr('month', -1); }} />}&nbsp;Month&nbsp;
                            {chunk === 'month' && <FaAnglesRight onClick={(e) => { e.stopPropagation(); setperiodstr('month', 1); }} />}
                        </div>
                        <div style={chunk === 'year' ? styles.chunksel : styles.chunk} onClick={(e) => { e.stopPropagation(); setperiodstr('year', 0); }}>
                            {chunk === 'year' && <FaAnglesLeft onClick={(e) => { e.stopPropagation(); setperiodstr('year', -1); }} />}&nbsp;Year&nbsp;
                            {chunk === 'year' && <FaAnglesRight onClick={(e) => { e.stopPropagation(); setperiodstr('year', 1); }} />}
                        </div>
                    </div>
                    <div style={styles.buttonwrap}>
                        <div style={styles.button}
                            onClick={async () => {
                                const lperiodfrom = scfromstr ? day_start(new Date(scfromstr)).getTime() : 0
                                const lperiodto = sctostr ? day_end(new Date(sctostr)).getTime() : 0
                                if ((periodfrom !== lperiodfrom || periodto !== lperiodto) && (lperiodfrom === 0 || lperiodfrom <= lperiodto || lperiodto === 0)) {
                                    dispatch(setSCPeriod({ periodfrom: lperiodfrom, periodto: lperiodto }))
                                    setstage(2)
                                    await calls.refetch()
                                    setstage(1)
                                } else {
                                    setscfromstr(periodfrom ? dateTostr(new Date(periodfrom), '-') : '')
                                    setsctostr(periodto ? dateTostr(new Date(periodto), '-') : '')
                                }
                                setaction('')
                            }}
                        >
                            Ok
                        </div>
                        <div style={styles.button}
                            onClick={() => {
                                setaction('')
                            }}

                        >
                            Cancel
                        </div>

                    </div>
                </div>
            </SubForm >
        }
        <div
            style={{
                cursor: 'pointer',
                fontSize: '1.2rem',
                paddingBottom: '2px',
                color: 'var(--DB-PERIOD-COLOR)',
                display: 'flex'
            }}
            onMouseDown={(e) => {
                e.stopPropagation()
                setaction('CHANGEPERIOD')
            }}
        >
            <FaAnglesLeft
                cursor={'alias'}
                onMouseDown={(e) => {
                    e.stopPropagation()
                    const lperiodfrom = periodfrom && periodfrom > 0 ? new Date(periodfrom) : null
                    if (lperiodfrom) lperiodfrom.setDate(lperiodfrom?.getDate() - 1)
                    const lperiodto = periodto && periodto > 0 ? new Date(periodto) : null
                    if (lperiodto) lperiodto.setDate(lperiodto?.getDate() - 1)
                    dispatch(setSCPeriod({ periodfrom: lperiodfrom ? lperiodfrom.getTime() : periodfrom, periodto: lperiodto ? lperiodto.getTime() : periodto }))
                    setscfromstr(lperiodfrom ? dateTostr(lperiodfrom, '-') : '')
                    setsctostr(lperiodto ? dateTostr(lperiodto, '-') : '')
                    calls.refetch()
                }}
                className='item_hover_scale_1_5'
                style={{ cursor: 'move', color: 'ButtonText' }}
            />&nbsp;
            {scfrom?.getFullYear() && scto?.getFullYear() && day_start(scfrom).getTime() === day_start(scto).getTime() ? date_localstr(scfrom)
                : scfrom?.getTime() && scto?.getTime() ? `${date_localstr(scfrom)} - ${date_localstr(scto)}`
                    : scfrom?.getTime() ? `from ${date_localstr(scfrom)}`
                        : scto?.getTime() ? `upto ${date_localstr(scto)}`
                            : 'All Period'
            }&nbsp;
            <FaAnglesRight
                onMouseDown={(e) => {
                    e.stopPropagation()
                    const lperiodfrom = periodfrom && periodfrom > 0 ? new Date(periodfrom) : null
                    if (lperiodfrom) lperiodfrom.setDate(lperiodfrom?.getDate() + 1)
                    const lperiodto = periodto && periodto > 0 ? new Date(periodto) : null
                    if (lperiodto) lperiodto.setDate(lperiodto?.getDate() + 1)
                    dispatch(setSCPeriod({ periodfrom: lperiodfrom ? lperiodfrom.getTime() : periodfrom, periodto: lperiodto ? lperiodto.getTime() : periodto }))
                    setscfromstr(lperiodfrom ? dateTostr(lperiodfrom, '-') : '')
                    setsctostr(lperiodto ? dateTostr(lperiodto, '-') : '')
                    calls.refetch()
                }}
                className='item_hover_scale_1_5'
                style={{ cursor: 'move', color: 'ButtonText' }}
            />

        </div>
    </>
    )
}

export default ChangePeriod