import React from 'react'

const SelectionTabField = ({ fieldsI, entry, setEntry }) => {

    if (!fieldsI?.length || entry.tab !== 'field') {
        return null
    }
    return fieldsI.map((fld, index) => (
        <div className='searcher_adv_item_pick' key={index}
            onMouseDown={() => {
                setEntry(entr => ({ ...entr, field: fld, tab: 'compare' }))
            }}
        >
            {fld.name}
        </div>
    ))

}

export default SelectionTabField