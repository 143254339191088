import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const facilitiesAdapter = createEntityAdapter({})

const initialState = facilitiesAdapter.getInitialState()

export const facilitiesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFacilities: builder.query({
            query: () => ({
                url: '/user/facilities',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedFacilities = responseData;
                loadedFacilities.id = '1';
                return facilitiesAdapter.setAll(initialState, [loadedFacilities])
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Facilities', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Facilities', id }))
                    ]
                } else return [{ type: 'Facilities', id: 'LIST' }]
            }
        })
    }),
})

export const {
    useGetFacilitiesQuery
} = facilitiesApiSlice

// returns the query result object
export const selectFacilitiesResult = facilitiesApiSlice.endpoints.getFacilities.select()

// creates memoized selector
const selectFacilitiesData = createSelector(
    selectFacilitiesResult,
    facilitiesResult => facilitiesResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllFacilities
    // Pass in a selector that returns the facilities slice of state
} = facilitiesAdapter.getSelectors(state => selectFacilitiesData(state) ?? initialState)