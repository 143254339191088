import { createSlice } from '@reduxjs/toolkit'
import { day_start, day_end } from '../utils/datefns'
export const globalSlice = createSlice({
  name: 'global',
  initialState: {
    settings: {
      fontSize: 16,
      dbloaded: false,
    },
    scrolldimen: {
      scrollTop: 0,
      scrollHeight: 0,
      offsetHeight: 0,
    },
    dashboard: {
      curtab: 'analysis',
      analmst: 'customer',
      canal: {
        row: 'Product Name',
        column: '',
        sortby: 'Product Name',
        sortasc: true,
        chart: 'line',
      },
      panal: {
        row: 'Name',
        column: '',
        sortby: 'Name',
        sortasc: true,
        chart: 'line',
      },
      uanal: {
        row: 'Call Fac.',
        column: '',
        sortby: 'Call Fac.',
        sortasc: true,
        chart: 'line',
      },
      scanal: {
        row: 'Status',
        column: '',
        sortby: 'Status',
        sortasc: true,
        chart: 'pie',
      },
      ranal: {
        row: 'Status',
        column: 'Type',
        sortby: 'Status',
        sortasc: true,
        chart: 'bar',
      },
    },
    servicecall: {
      // periodfrom: Date.now() - new Date(new Date().getDate() - 7).getTime(),
      // periodfrom: new Date(new Date().setDate(-6)).setHours(0, 0, 0, 0),
      // periodto: new Date(new Date().setHours(23, 59, 59, 999)).getTime(),
      periodfrom: day_start().getTime(),
      periodto: day_end().getTime(),
      forcefetch: false,
      selection: [],
      status: 'All',
      sortby: 'No',
      sortasc: true,
      currentline: 0,
    },
    customersrepcfg: {
      selection: [],
      sortby: 'Name',
      sortasc: true,
      currentline: 0,
      scrolltop: 0,
      batching: 0,
    },
    renewalcfg: {
      selection: [],
      TSSExpired: true,
      TSSExpiring: true,
      AMCExpired: true,
      AMCExpiring: true,
      status: 'All',
      followupfrom: 0,
      followupto: 0,
      sortby: 'Customer Name',
      sortasc: true,
      dbloaded: false,
    },
    productsrepcfg: {
      selection: [],
      sortby: 'Name',
      sortasc: true,
      currentline: 0,
    },
    usersrepcfg: {
      selection: [],
      sortby: 'Name',
      sortasc: true,
      currentline: 0,
    },
    sidebaropen: window.innerWidth > 768,
  },
  reducers: {
    setSCPeriod: (state, action) => {
      state.servicecall.periodfrom = action.payload.periodfrom ?? 0
      state.servicecall.periodto = action.payload.periodto ?? 0
      state.servicecall.forcefetch = true
    },
    setSCforcefetch: (state, action) => {
      state.servicecall.forcefetch = action.payload
    },
    setSCselection: (state, action) => {
      state.servicecall.selection = action.payload ?? []
    },
    setSCstatus: (state, action) => {
      state.servicecall.status = action.payload ?? 'All'
    },
    setSCsortby: (state, action) => {
      state.servicecall.sortby = action.payload ?? 'No'
    },
    setSCsortasc: (state, action) => {
      state.servicecall.sortasc = action.payload ?? true
    },
    setSCcurrentline: (state, action) => {
      state.servicecall.currentline = action.payload ?? 0
    },
    setSidebaropen: (state, action) => {
      state.sidebaropen = action.payload
    },
    setcustomersrepcfg: (state, action) => {
      state.customersrepcfg = action.payload
    },
    setscrepcfg: (state, action) => {
      state.screpcfg = action.payload
    },
    setrenewalcfg: (state, action) => {
      state.renewalcfg = action.payload
    },
    setscrolldimen: (state, action) => {
      state.scrolldimen = action.payload
    },
    setdashboard: (state, action) => {
      state.dashboard = action.payload
    },
    setsettings: (state, action) => {
      state.settings = action.payload
    },
    setproductsrepcfg: (state, action) => {
      state.productsrepcfg = action.payload
    },
    setusersrepcfg: (state, action) => {
      state.usersrepcfg = action.payload
    },
  }
})

// Action creators are generated for each case reducer function
export const { setSCPeriod, setSCselection, setSCstatus, setSCsortby, setSCsortasc, setSCcurrentline,
  setSidebaropen, setcustomersrepcfg, setscrepcfg, setrenewalcfg, setusersrepcfg, setSCforcefetch,
  setscrolldimen, setdashboard, setsettings, setproductsrepcfg } = globalSlice.actions


export default globalSlice.reducer