import { useAddNewProductMutation, useDeleteProductMutation, useUpdateProductMutation } from "../../../features/productsApiSlice"

class TProduct {
    constructor(prod) {
        this.id = prod ? prod.id : ''
        this._id = prod ? prod._id : ''
        this.name = prod ? prod.name : ''
        this.active = prod ? prod.active : true
        this.description = prod ? prod.description : ''
    }
}

const TUpdater = () => {
    const [updateProduct, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateProductMutation();
    return { updateProduct, isLoading, isSuccess, isError, error }

}
const TCreator = () => {
    const [addNewProduct, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewProductMutation();
    return { addNewProduct, isLoading, isSuccess, isError, error }
}

const TDeleter = () => {
    const [deleteProduct, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useDeleteProductMutation();
    return { deleteProduct, isLoading, isSuccess, isError, error }
}


export { TProduct, TUpdater, TCreator, TDeleter };


