import React from 'react'

const SelectionTabTitles = ({entry, setEntry}) => {
    return <div className='searcher_adv_tabs_cont2'>
        <div className='searcher_adv_tab' style={{ backgroundColor: entry.tab === 'field' ? 'var(--DB-ITEM-SELECTED)' : '#eee', borderTopLeftRadius: '10px' }}
            onMouseDown={() => {
                setEntry(entr => ({ ...entr, tab: 'field' }))
            }}
        >{entry.field?.name ? entry.field?.name : 'Field'}</div>
        <div className='searcher_adv_tab' style={{ backgroundColor: entry.tab === 'compare' ? 'var(--DB-ITEM-SELECTED)' : '#eee', borderLeft: '1px solid #fff' }}
            onMouseDown={() => {
                setEntry(entr => ({ ...entr, tab: 'compare' }))
            }}
        >{entry.compare ? entry.compare : 'Compare'}</div>
        <div className='searcher_adv_tab' style={{ backgroundColor: entry.tab === 'value' ? 'var(--DB-ITEM-SELECTED)' : '#eee', borderLeft: '1px solid #fff', borderTopRightRadius: '10px' }}
            onMouseDown={() => {
                setEntry(entr => ({ ...entr, tab: 'value' }))
            }}
        >Value(s)</div>
    </div>

}

export default SelectionTabTitles