import {
    createSelector,
    createEntityAdapter
} from "@reduxjs/toolkit";
import { apiSlice } from "../app/api/apiSlice"
import { setSCforcefetch } from '../features/globalSlice'

const callsAdapter = createEntityAdapter({})

const initialState = callsAdapter.getInitialState()


export const callsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCalls: builder.query({
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                const state = api.getState()
                const xData = state.api.queries[`getCalls("${arg}")`].data
                const xFetchTime = xData?.fetchtime

                try {
                    const { data } = await baseQuery({
                        url: '/servicecall',
                        params: { time: state.global.servicecall.forcefetch ? '' : xFetchTime, periodfrom: state.global.servicecall.periodfrom, periodto: state.global.servicecall.periodto },
                        validateStatus: (response, result) => {
                            return response.status === 200 && !result.isError
                        },
                    })
                    let ids = []
                    let entities = {}
                    if (xData?.ids?.length > 0 && !state.global.servicecall.forcefetch) {
                        xData.ids.map(lid => {
                            if ((!data || !data?.deleted || data?.deleted?.length === 0 || !data.deleted.includes(lid)) && xData.entities[lid]) {
                                ids.push(lid)
                                entities[lid] = xData.entities[lid]
                            }
                            return false
                        })
                    }
                    data?.docs?.map(doc => {
                        if (!ids.includes(doc._id)) ids.push(doc._id)
                        entities[doc._id] = ({
                            id: doc._id, ...doc,
                            status: doc.attendedby && !doc.open ? 'Disposed'
                                : doc.attendedby ? 'Attended'
                                    : doc.assignedto ? 'Assigned'
                                        : 'Created'
                        })
                        return false
                    })
                    const fetchtime = data?.fetchtime ? data?.fetchtime : xFetchTime ? xFetchTime : ''
                    return ({ data: { ids, entities, fetchtime } })
                } catch (error) {
                    return { error }
                }
            },
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    await queryFulfilled
                    dispatch(setSCforcefetch(false))
                } catch (err) {
                    console.log(err)
                }
            },
            /* query: () => ({
                url: '/servicecall',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }), */
            transformResponse: responseData => {
                const loadedCalls = responseData.map(call => {
                    call.id = call._id
                    call.status = call.attendedby && !call.open ? 'Disposed'
                        : call.attendedby ? 'Attended'
                            : call.assignedto ? 'Assigned'
                                : 'Created'
                    return call
                });
                return callsAdapter.setAll(initialState, loadedCalls)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Servicecall', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Servicecall', id }))
                    ]
                } else return [{ type: 'Servicecall', id: 'LIST' }]
            }
        }),
        addNewCall: builder.mutation({
            query: initialCallData => ({
                url: '/servicecall',
                method: 'POST',
                body: {
                    ...initialCallData,
                }
            }),
            invalidatesTags: [
                { type: 'Servicecall', id: "LIST" }
            ]
        }),
        assignCall: builder.mutation({
            query: initialCallData => ({
                url: '/servicecall/assign',
                method: 'PATCH',
                body: initialCallData
            }),
            invalidatesTags: [
                { type: 'Servicecall', id: "LIST" }
            ]
        }),
        attendCall: builder.mutation({
            query: initialCallData => ({
                url: '/servicecall/attend',
                method: 'PATCH',
                body: initialCallData,
            }),
            invalidatesTags: [
                { type: 'Servicecall', id: "LIST" }
            ]
        }),
        disposeCall: builder.mutation({
            query: initialCallData => ({
                url: '/servicecall/dispose',
                method: 'PATCH',
                body: initialCallData,
            }),
            invalidatesTags: [
                { type: 'Servicecall', id: "LIST" }
            ]
        }),
        revertCall: builder.mutation({
            query: initialCallData => ({
                url: '/servicecall/revert',
                method: 'PATCH',
                body: initialCallData,
            }),
            invalidatesTags: [
                { type: 'Servicecall', id: "LIST" }
            ]
        }),
        updateCall: builder.mutation({
            query: initialCallData => ({
                url: '/servicecall',
                method: 'PATCH',
                body: {
                    ...initialCallData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Servicecall', id: arg.id }
            ]
        }),
        deleteCall: builder.mutation({
            query: ({ id }) => ({
                url: `/servicecall`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Servicecall', id: arg.id }
            ]
        }),
        getNextReference: builder.query({
            query: (params) => ({
                url: '/servicecall/nextreference',
                params,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
                },
            }),
            transformResponse: responseData => {
                const loadedRefs = responseData.map(call => {
                    call.id = call._id
                    return call
                });
                return callsAdapter.setAll(initialState, loadedRefs)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'ServicecallRefs', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'ServicecallRefs', id }))
                    ]
                } else return [{ type: 'ServicecallRefs', id: 'LIST' }]
            }
        }),

    }),
})

export const {
    useGetCallsQuery,
    useAddNewCallMutation,
    useAssignCallMutation,
    useAttendCallMutation,
    useDisposeCallMutation,
    useRevertCallMutation,
    useUpdateCallMutation,
    useDeleteCallMutation,
    useGetNextReferenceQuery,
} = callsApiSlice

// returns the query result object
export const selectCallsResult = callsApiSlice.endpoints.getCalls.select()

// creates memoized selector
const selectCallsData = createSelector(
    selectCallsResult,
    callsResult => callsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllCalls,
    selectById: selectCallById,
    selectIds: selectCallIds
    // Pass in a selector that returns the calls slice of state
} = callsAdapter.getSelectors(state => selectCallsData(state) ?? initialState)