import React, { useState } from 'react'
import "./Copyright.css"

const Copyright = ({ stylep }) => {
    const [style] = useState({ fontSize: '14px', fontFamily: 'sans-serif', letterSpacing: '1px', fontStyle: 'italic', color: 'GrayText' })
    return (
        <div  >
            <div style={{...style, display:'flex'}}><div className='brand_logo' />&nbsp;1.0</div>
            <div style={{ ...style, ...stylep }}>©2023 <a href="https://www.techneh.com" target='_blank' style={{ textDecoration: 'none', color: 'inherit' }} rel='noreferrer'>techneh.com</a> </div>
        </div>
    )
}

export default Copyright