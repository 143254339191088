import React, { createContext, useContext, useRef, useState } from "react";
import { TCall, TCreator, TDeleter, TUpdater, TNextRef, TAssigner, TAttender, TDisposer, TReverter } from "./Classes";
import useAuthorize from "../../../hooks/useAuthorize";
import Searcher from "../common/Searcher/Searcher"
import GC from "../context/GC";
import EditTableColumns from "../common/EditTableColumns/EditTableColumns";

const SCContext = createContext()


export const SCContextProvider = ({ children }) => {
    // console.log('Render : SCContext');
    const { customers, users, thisuser, userconfig } = useContext(GC)
    const [call, setCall] = useState(new TCall())
    const [callID, setCallID] = useState('')
    const selection = useRef([])
    const updater = TUpdater()
    const deleter = TDeleter()
    const creator = TCreator()
    const assigner = TAssigner()
    const attender = TAttender()
    const disposer = TDisposer()
    const reverter = TReverter()
    const next_ref = TNextRef()
    const { FL } = useContext(GC);
    const allow = {
        create: useAuthorize([FL?.SCALL_CREATE]),
        read: useAuthorize([FL?.SCALL_READ]),
        delete: useAuthorize([FL?.SCALL_DELETE]),
        edit: useAuthorize([FL?.SCALL_EDIT]),
        assign: useAuthorize([FL?.SCALL_ASSIGN]),
        attend: useAuthorize([FL?.SCALL_ATTEND]),
        dispose: useAuthorize([FL?.SCALL_DISPOSE]),
    }

    const fieldsarr = [
        { name: 'No', method: ({ reference }) => reference, },
        { name: 'Date', method: ({ date }) => date, type: 'date' },
        { name: 'Date & Time', method: ({ date }) => date, type: 'datetime-local' },
        { name: 'Apmt. Date', method: ({ appointment_time }) => appointment_time, type: 'date' },
        { name: 'Apmt. Date-time', method: ({ appointment_time }) => appointment_time, type: 'datetime-local' },
        { name: 'Customer Name', method: ({ customer }) => customers?.data?.entities[customer]?.name, options: customers.selectOptions ? [...customers.selectOptions] : [] },
        { name: 'Address', method: ({ customer }) => customers?.data?.entities[customer]?.address },
        { name: 'Country', method: ({ customer }) => customers?.data?.entities[customer]?.country },
        { name: 'State', method: ({ customer }) => customers?.data?.entities[customer]?.state },
        { name: 'District', method: ({ customer }) => customers?.data?.entities[customer]?.district },
        { name: 'Mobile', method: ({ customer }) => customers?.data?.entities[customer]?.mobile },
        { name: 'Contact', method: ({ customer }) => customers?.data?.entities[customer]?.contact },
        { name: 'Email', method: ({ customer }) => customers?.data?.entities[customer]?.email },
        { name: 'Designation', method: ({ customer }) => customers?.data?.entities[customer]?.designation },
        { name: 'Product Name', method: ({ customer }) => customers?.data?.entities[customer]?.products?.map(prod => prod.name) },
        { name: 'Serial Number', method: ({ customer }) => customers?.data?.entities[customer]?.products?.map(prod => prod.serialnumber) },
        { name: 'Prod.Expiry', method: ({ customer }) => customers?.data?.entities[customer]?.products?.map(prod => prod.TSSExpiry), type: 'date' },
        { name: 'Type', method: ({ callType }) => callType, options: [{ label: 'On-site', value: 'On-site' }, { label: 'Remote', value: 'Remote' }] },
        { name: 'Charge', method: ({ chargeType }) => chargeType, options: [{ label: 'Free', value: 'Free' }, { label: 'AMC', value: 'AMC' }, { label: 'Per Call', value: 'Per Call' }] },
        { name: 'Complaint', method: ({ problem_description }) => problem_description },
        { name: 'Status', method: ({ status }) => status },
        { name: 'User', method: ({ attendedby, assignedto, createdby, disposedby }) => users?.data?.entities[disposedby ? disposedby : attendedby ? attendedby : assignedto ? assignedto : createdby]?.username },
        { name: 'Created By', method: ({ createdby }) => users?.data?.entities[createdby]?.username, options: users?.selectOptions ? [...users.selectOptions] : [] },
        { name: 'Assigned By', method: ({ assignedby }) => users?.data?.entities[assignedby]?.username, options: users?.selectOptions ? [...users.selectOptions] : [] },
        { name: 'Assigned To', method: ({ assignedto }) => users?.data?.entities[assignedto]?.username, options: users?.selectOptions ? [...users.selectOptions] : [] },
        { name: 'Attended By', method: ({ attendedby }) => users?.data?.entities[attendedby]?.username, options: users?.selectOptions ? [...users.selectOptions] : [] },
        { name: 'Disposed By', method: ({ disposedby }) => users?.data?.entities[disposedby]?.username, options: users?.selectOptions ? [...users.selectOptions] : [] },
    ]
    const get_fields = () => {
        let fields = {}
        fieldsarr.map(lfld => {
            fields[lfld.name] = lfld
            return true
        })
        return fields
    }
    const fields = get_fields()

    const searcher = Searcher({
        fieldsarr,
        fullobj_s: false,
    })

    const tableediter = EditTableColumns(
        {
            fieldsarr: [...fieldsarr],
            selected: thisuser?.colsscreport ? thisuser.colsscreport : [],
            saveselected: async function (selected) {
                if (userconfig[0]) {
                    await userconfig[0]({ colsscreport: [...selected] })
                }
            }
        }
    )

    return (
        <SCContext.Provider value={{
            allow,
            call, setCall,
            callID, setCallID,
            updater, deleter, creator, assigner, attender, disposer, reverter, next_ref,
            searcher,
            fields, fieldsarr, tableediter,
            selection,
        }}>
            {children}
        </SCContext.Provider>
    )

}

export default SCContext