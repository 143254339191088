import React from 'react'
import { RotatingLines } from 'react-loader-spinner'
// import PulseLoader from 'react-spinners/PulseLoader'

const LoaderSpinner = ({ zIndex = 100 }) => {
    return (
        <div
            style={{ backgroundColor: 'transparent', zIndex, position: 'fixed', top: 'calc(50vh - 30px)', left: 'calc(50vw - 30px)' }}
        >
            <RotatingLines
                strokeColor="var(--LOADER-SPINNER-COLOR)"
                strokeWidth="5"
                animationDuration="0.75"
                width="60"
                visible={true}
            />
        </div>
    )
}

export default LoaderSpinner
// <PulseLoader color={"var(--DB-PULSELOADER-COLOR)"} />