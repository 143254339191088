import React, { createContext, useContext, useState } from "react";
import { TCustomer, TCreator, TDeleter, TUpdater } from "./CClass";
import useAuthorize from "../../../hooks/useAuthorize";
import Searcher from "../common/Searcher/Searcher"
import GC from "../context/GC";
import EditTableColumns from "../common/EditTableColumns/EditTableColumns";

const CContext = createContext()


export const CContextProvider = ({ children }) => {
    const [customer, setCustomer] = useState(new TCustomer())
    const [customerID, setCustomerID] = useState('')
    const [customerName, setCustomerName] = useState('')
    const updater = TUpdater()
    const deleter = TDeleter()
    const creator = TCreator()
    const { FL, thisuser, userconfig, products, customers } = useContext(GC);
    const allow = {
        create: useAuthorize([FL?.CUSTOMER_CREATE]),
        read: useAuthorize([FL?.CUSTOMER_READ]),
        delete: useAuthorize([FL?.CUSTOMER_DELETE]),
        edit: useAuthorize([FL?.CUSTOMER_EDIT]),
    }


    const fieldsarr = [
        { name: 'Name', method: ({ name }) => name, options: customers.selectOptions },
        { name: 'Address', method: ({ address }) => address },
        { name: 'Country', method: ({ country }) => country },
        { name: 'State', method: ({ state }) => state },
        { name: 'District', method: ({ district }) => district },
        { name: 'Mobile', method: ({ mobile }) => mobile, tdstyle: { maxWidth: '8rem' } },
        { name: 'Contact', method: ({ contact }) => contact },
        { name: 'Email', method: ({ email }) => email },
        { name: 'Designation', method: ({ designation }) => designation },
        { name: 'Status', method: ({ active }) => active ? 'Active' : 'Disabled', options: [{ value: 'Active', label: 'Active' }, { value: 'Disabled', label: 'Disabled' }], compare: [['equals']] },
        { name: 'Product Name', method: ({ products: lproducts }) => lproducts?.map(prod => products?.data?.entities[prod.product]?.name) },
        { name: 'Serial Number', method: ({ products }) => products?.map(prod => prod.serialnumber) },
        { name: 'Prod.Expiry', method: ({ products }) => products?.map(prod => prod.TSSExpiry), type: 'date', tdstyle: { maxWidth: '8rem' } },
        { name: 'Addon', method: ({ products }) => products?.map(prod => prod.TDL) },
        { name: 'eInvoice', method: ({ products }) => products?.map(prod => prod.eInvoice ? 'Yes' : 'No'), options: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }], compare: [['equals']] },
        { name: 'eWayBill', method: ({ products }) => products?.map(prod => prod.eWayBill ? 'Yes' : 'No'), options: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }], compare: [['equals']] },
        { name: 'Free Calls', method: ({ products }) => products?.map(prod => prod.freecalls), type: 'number' },
        { name: 'AMC From', method: ({ AMC }) => AMC?.map(amc => amc.from), type: 'date' },
        { name: 'AMC To', method: ({ AMC }) => AMC?.map(amc => amc.to), type: 'date' },
        { name: 'AMC Amount', method: ({ AMC }) => AMC?.map(amc => amc.amount), type: 'number' },
        { name: 'AMC Calls', method: ({ AMC }) => AMC?.map(amc => amc.calls), type: 'number' },
        { name: 'Bal.AMC Calls', method: ({ AMC }) => AMC?.map(amc => amc.calls - (amc.usedcalls > 0 ? amc.usedcalls : 0)), type: 'number' },
        { name: 'Bal.Free Calls', method: ({ products }) => products?.map(prod => prod.calls - (prod.usedcalls > 0 ? prod.usedcalls : 0)), type: 'number' },
    ]

    const get_fields = () => {
        let fields = {}
        fieldsarr.map(lfld => {
            fields[lfld.name] = lfld
            return true
        })
        return fields
    }
    const fields = get_fields()


    const tableediter = EditTableColumns(
        {
            fieldsarr: [...fieldsarr],
            selected: thisuser?.colscustreport ? thisuser.colscustreport : [],
            saveselected: async function (selected) {
                if (userconfig[0]) {
                    await userconfig[0]({ colscustreport: [...selected] })
                }
            }
        }
    )
    const searcher = Searcher({
        fieldsarr
    })



    return (
        <CContext.Provider value={{
            allow,
            customer, setCustomer,
            customerID, setCustomerID, customerName, setCustomerName,
            updater, deleter, creator,
            fields, fieldsarr,
            searcher,
            tableediter,
        }}>
            {children}
        </CContext.Provider>
    )

}

export default CContext