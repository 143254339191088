import React, { useEffect, useRef } from 'react'

const SubForm = ({ children, disposer, style1 }) => {
    const divref = useRef(null);
    useEffect(() => {
        divref.current.focus();
    }, [divref])
    const handle_key = (e) => {
        e.stopPropagation()
        if (e?.key === 'Escape' && e?.target?.id === 'SubForm_back') {
            disposer();
        }
    }
    return (
        <div ref={divref} id='SubForm_back' className='SubForm_back' onClick={(e) => { e.stopPropagation(); if (e.target.id === 'SubForm_back') disposer() }} tabIndex={0} onKeyDown={(e) => handle_key(e)} style={style1}>
            <div className="SubForm_content_rel">
                <div className="SubForm_content">
                    {children}
                </div>
            </div>
        </div>
    )

}

export default SubForm