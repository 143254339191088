import { useContext, useRef } from "react"

import { GoSidebarCollapse, GoSidebarExpand } from 'react-icons/go'
import { AiFillCustomerService } from 'react-icons/ai'
import { RxDashboard } from 'react-icons/rx';
// import { GrScheduleNew } from 'react-icons/gr';
import { AiOutlineSchedule } from 'react-icons/ai';
import { FaPeopleLine } from 'react-icons/fa6'
import { MdOutlineInventory } from 'react-icons/md'
import { FiUsers } from 'react-icons/fi'
import useAuthorize from '../../hooks/useAuthorize';


import { Link, useLocation } from "react-router-dom";
import GC from "./context/GC";
import { useDispatch, useSelector } from "react-redux";
import { setSidebaropen } from "../../features/globalSlice";
import Copyright from "./Copyright";

const REGEX_HOME = /^\/home(\/)?$/
const REGEX_SERVICECALL = /^\/home\/servicecall(\/)?$/
const REGEX_USERS = /^\/home\/users(\/)?$/
const REGEX_PRODUCTS = /^\/home\/products(\/)?$/
const REGEX_CUSTOMERS = /^\/home\/customers(\/)?$/
const REGEX_RENEWAL = /^\/home\/renewal(\/)?$/

const DashNav = () => {
  const { pathname } = useLocation();
  const sidebaropen = useSelector(state => state.global.sidebaropen)
  const dispatch = useDispatch()

  const { FL, thisuser } = useContext(GC);
  const dashnavref = useRef(null)


  // useEffect(() => {
  //   // document.getElementById("dash_nav").classList.toggle('collapsed')
  //   document.getElementById("dash_body").classList.toggle('expanded')
  // }, [sideBarOpen])


  // local Components
  const nav_buttons = {
    dashboard: (
      <Link
        to="/home"
        className={`${REGEX_HOME.test(pathname) ? 'dash_nav_item_focused' : 'dash_nav_item'}`}>
        <RxDashboard className="dash_nav_icon" style={{ color: '#3a9dbc' }} />
        <span className="dash_nav_title">Dashboard</span>
      </Link>
    ),
    serivceCall: (
      ((useAuthorize([FL?.SCALL_CREATE, FL?.SCALL_EDIT, FL?.SCALL_DELETE, FL?.SCALL_READ])) || (thisuser.hasanycallright)) &&
      <Link
        to="/home/servicecall"
        className={`${REGEX_SERVICECALL.test(pathname) ? 'dash_nav_item_focused' : 'dash_nav_item'}`}>
        <AiFillCustomerService className="dash_nav_icon" style={{ color: '#914040' }} />
        <span className="dash_nav_title">Service Call</span>
      </Link>
    ),
    customers: (
      useAuthorize([FL?.CUSTOMER_CREATE, FL?.CUSTOMER_EDIT, FL?.CUSTOMER_DELETE, FL?.CUSTOMER_READ]) &&
      <Link
        to="/home/customers"
        className={`${REGEX_CUSTOMERS.test(pathname) ? 'dash_nav_item_focused' : 'dash_nav_item'}`}>
        <FaPeopleLine className="dash_nav_icon" style={{ color: "#587436" }} />
        <span className="dash_nav_title">Customers</span>
      </Link>
    ),
    renewal: (
      <Link
        to="/home/renewal"
        className={`${REGEX_RENEWAL.test(pathname) ? 'dash_nav_item_focused' : 'dash_nav_item'}`}>
        <AiOutlineSchedule className="dash_nav_icon" style={{ color: '#4b55ad' }} />
        <span className="dash_nav_title">Renewal</span>
      </Link>
    ),
    products: (
      useAuthorize([FL?.PRODUCT_CREATE, FL?.PRODUCT_EDIT, FL?.PRODUCT_DELETE, FL?.PRODUCT_READ]) &&
      <Link
        to='/home/products'
        className={`${REGEX_PRODUCTS.test(pathname) ? 'dash_nav_item_focused' : 'dash_nav_item'}`}>
        <MdOutlineInventory className="dash_nav_icon" style={{ color: "#135ab0" }} />
        <span className="dash_nav_title">Products</span>
      </Link>
    ),
    users: (
      useAuthorize([FL?.USER_CREATE, FL?.USER_EDIT, FL?.USER_DELETE, FL?.USER_READ]) &&
      <Link
        to="/home/users"
        className={`${REGEX_USERS.test(pathname) ? 'dash_nav_item_focused' : 'dash_nav_item'}`}>
        <FiUsers className="dash_nav_icon" style={{ color: "#e67e22" }} />
        <span className="dash_nav_title">Users</span>
      </Link>
    ),
  }

  if (!thisuser.username) {
    return null
  } else {
    return (
      <>
        <button className="dash_nav_toggle_btn"
          onClick={() => {
            dispatch(setSidebaropen(!sidebaropen))
            if (window.innerWidth <= 768 && !sidebaropen) {
              dashnavref?.current.focus()
            }
          }}
        >{!sidebaropen ? <GoSidebarCollapse className="dash_nav_collapse_icon" /> : <GoSidebarExpand className="dash_nav_expand_icon" />}</button>

        <nav
          id="dash_nav"
          className={sidebaropen ? 'dash_nav' : 'dash_nav collapsed'}
          tabIndex={2}
          ref={dashnavref}
          onBlur={(e) => {
            if (window.innerWidth <= 768)
              dispatch(setSidebaropen(false))
          }}
        >
          <div className="dash_nav_items">
            {nav_buttons.dashboard}
            {thisuser.renewal && nav_buttons.renewal}
            {nav_buttons.serivceCall}
            {nav_buttons.customers}
            {nav_buttons.products}
            {nav_buttons.users}
          </div>
          <Copyright />
        </nav>
      </>
    )
  }
}

export default DashNav