import { store } from '../../app/store'
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { facilitiesApiSlice } from '../facilities/facilitiesApiSlice';

const PrefetchFacilities = () => {
    useEffect(() => {
        store.dispatch(facilitiesApiSlice.util.prefetch('getFacilities', 'facilitiesList', { force: true }))
    }, [])

    return <Outlet />
}
export default PrefetchFacilities
