import React from 'react'

const ControlButtons = ({ setAll, setAny, clear, join, rules }) => {
    return <div className='searcher_control_btns_box'>
        <button type='button' style={{ minWidth: '5rem', border: 'none', outline: 'none', padding: '0.5rem', borderRadius: '10px', backgroundColor: join === 'ALL' ? '#d19494' : '#eee' }}
            onClick={setAll}
            disabled={!rules?.length}
        >Apply All</button>
        <button type='button' style={{ minWidth: '5rem', border: 'none', outline: 'none', padding: '0.5rem', borderRadius: '10px', backgroundColor: join === 'ANY' ? '#d19494' : '#eee' }}
            onClick={setAny}
            disabled={!rules?.length}
        >Apply Any</button>
        <button type='button' style={{ minWidth: '5rem', border: 'none', outline: 'none', padding: '0.5rem', borderRadius: '10px' }}
            onClick={clear}
        >Clear</button>
    </div>
}

export default ControlButtons