import { FcHome } from 'react-icons/fc'
import { BiUser } from 'react-icons/bi'
import { MdLogout } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom';
import { useSendLogoutMutation } from '../../features/auth/authApiSlice';
import { memo, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import { useSelector } from 'react-redux';
import AppHeader from './AppHeader'
import ChangePeriod from './servicecall/ChangePeriod/ChangePeriod';

const HOME_REGEX = /^\/home(\/)?$/
const REGEX_PROFILE = /^\/home\/profile(\/)?$/
const REGEX_SC = /^\/home\/servicecall(\/)?$/
const DashHeader = () => {
    const dashboard = useSelector(state => state.global.dashboard)
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { username } = useAuth();
    const [sendLogout, {
        isSuccess,
    }] = useSendLogoutMutation();
    useEffect(() => {
        if (!username || isSuccess) navigate('/')
    }, [isSuccess, navigate, username]);
    if (!username) {
        return <AppHeader />
    } else {
        return (
            <AppHeader>
                {username &&
                    <div className="header_right_icons">
                        {/* <ServiceCallPeriod> */}
                        {(REGEX_SC.test(pathname) || (HOME_REGEX.test(pathname) && dashboard.curtab === 'analysis' && dashboard.analmst === 'call')) && <ChangePeriod />}
                        {/* </ServiceCallPeriod> */}
                        {username && !REGEX_PROFILE.test(pathname) &&
                            <div className='margin_right_2 flex_horizontal' onClick={() => navigate('/home/profile')}>
                                <button
                                    className='icon_button'
                                    title={`${username} signed in`}
                                >
                                    <BiUser className='icon_user' />
                                </button><span className='dash_username'>{username}</span>
                            </div>
                        }
                        {!HOME_REGEX.test(pathname) &&
                            <button
                                className='icon_button'
                                title='Go Home'
                                onClick={() => navigate('/home')}
                            >
                                <FcHome className='icon_home ' />
                            </button>
                        }
                        {username &&
                            <button
                                className='icon_button'
                                title='Sign Out'
                                onClick={sendLogout}
                            >
                                <MdLogout className='icon_logout' />
                            </button>
                        }
                    </div>
                }
            </AppHeader>
        )
    }
}
const memoizedDashHeader = memo(DashHeader);
export default memoizedDashHeader