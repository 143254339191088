import { useSelector } from "react-redux"
import { Outlet, useLocation } from "react-router-dom"

const REGEX_HOME = /^\/home(\/)?$/
const DashBody = () => {
  const sidebaropen = useSelector(state => state.global.sidebaropen)
  const { pathname } = useLocation();
  return (
    <div id="dash_body" className={sidebaropen ? 'dash_body' : 'dash_body expanded'} style={REGEX_HOME.test(pathname) ? { backgroundColor: '#fbfbfb' } : null}>
      <Outlet />
    </div>
  )
}

export default DashBody