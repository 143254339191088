import { useSelector } from 'react-redux'
import { selectCurrentToken } from "../features/auth/authSlice"
import jwtDecode from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    const loggedin = (localStorage.getItem('loggedin')==='true') || false
    if (token && loggedin) {
        const decoded = jwtDecode(token)
        const { username, userid, facilities, isAdmin } = decoded.UserInfo
        const status = isAdmin ? "Admin" : 'user';
        return { username, userid, facilities, isAdmin, status }
    }

    return { username: '', facilities: [], isAdmin: false, status: '' }
}
export default useAuth