import { memo} from 'react';

const AppHeader = ({ children }) => {
    return (
        <header className="dash_header">
            <div className='dash_header_logo_vt' />
            {/* <img src='img/VEETECH.png' alt='' height="65" style={{ borderRadius: '0.2rem' }} /> */}
            {/* <img src='img/logo.png' alt='' height="60" style={{ borderRadius: '0.2rem', opacity: '0.9' }} /> */}
            {/* <span style={{ letterSpacing: '2px', fontSize: '1.4em', paddingLeft: '0.5rem' }}>VEETECH</span>
            <span style={{ paddingLeft: '0.5rem', paddingBottom: '0.1rem' }}>Software Solutions Pvt.Ltd.</span> */}
            {children}
        </header>
    )
}
const memoizedAppHeader = memo(AppHeader);
export default memoizedAppHeader
