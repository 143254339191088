import { useAddNewCustomerMutation, useDeleteCustomerMutation, useUpdateCustomerMutation } from "../../../features/customersApiSlice"

class TProduct {
    constructor() {
        this.id = ''
        this.product = ''
        this.name = ''
        this.serialnumber = ''
        this.version = ''
        this.date = ''
        this.email = ''
        this.password = ''
        this.TSSExpiry = ''
        this.TDL = ''
        this.eInvoice = false
        this.eWayBill = false
        this.freecalls = 0
    }
    load(prod) {
        if (prod?.id) {
            this.id = prod.id
            this.product = prod.product
            this.name = prod.name
            this.serialnumber = prod.serialnumber
            this.version = prod.version
            this.date = prod.date
            this.email = prod.email
            this.password = prod.password
            this.TSSExpiry = prod.TSSExpiry
            this.TDL = prod.TDL
            this.eInvoice = prod.eInvoice
            this.eWayBill = prod.eWayBill
            this.freecalls = prod.freecalls
        }
    }
}

class TAMC {
    constructor() {
        this.id = ''
        this.from = ''
        this.to = ''
        this.amount = ''
        this.calls = ''
    }
    load(amc) {
        if (amc?.id) {
            this.id = amc.id
            this.from = amc.from
            this.to = amc.to
            this.amount = amc.amount
            this.calls = amc.calls
        }
    }
}

class TCustomer {
    constructor() {
        this.id = ''
        this.name = ''
        this.createdby = ''
        this.address = ''
        this.country = ''
        this.state = ''
        this.district = ''
        this.mobile = ''
        this.contact = ''
        this.email = ''
        this.designation = ''
        this.active = true
        this.active = true
        this.products = []
        this.product = ''
        this.AMC = []
    }
    load(customer) {
        if (customer?.id) {
            this.id = customer.id
            this.name = customer.name
            this.createdby = customer.createdby
            this.address = customer.address
            this.country = customer.country
            this.state = customer.state
            this.district = customer.district
            this.mobile = customer.mobile
            this.contact = customer.contact
            this.email = customer.email
            this.designation = customer.designation
            this.active = customer.active
            this.active = customer.active
            this.products = customer.products
            this.product = customer.product
            this.AMC = customer.AMC
        }
    }
}

const TUpdater = () => {
    const [updateCustomer, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateCustomerMutation();
    return { updateCustomer, isLoading, isSuccess, isError, error }

}
const TCreator = () => {
    const [addNewCustomer, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewCustomerMutation();
    return { addNewCustomer, isLoading, isSuccess, isError, error }
}

const TDeleter = () => {
    const [deleteCustomer, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useDeleteCustomerMutation();
    return { deleteCustomer, isLoading, isSuccess, isError, error }
}


export { TProduct, TAMC, TCustomer, TUpdater, TCreator, TDeleter };


