import DashHeader from "./DashHeader"
import DashNav from "./DashNav"
import DashBody from "./DashBody"

const DashLayout = () => {
    return (
        <div className="dash_container">
            <DashHeader />
            <div className="dash_body_main">
                <DashNav />
                <DashBody />
            </div>
        </div>
    )
}

export default DashLayout