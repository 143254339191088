import { useLocation, Navigate, Outlet } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import { useEffect } from "react"

const RequireAuth = () => {
    const location = useLocation()
    const { isAdmin, status } = useAuth()
    useEffect(() => {
        if (!status) {
            localStorage.setItem("loggedin", false)
        }
    }, [status])
    const content = (
        isAdmin || status
            ? <Outlet />
            : <Navigate to="/" state={{ from: location }} replace />
    )

    return content
}
export default RequireAuth