import React from 'react'

const InputWithLabel = ({ title,
    type = 'text',
    value,
    on_change,
    label_width = '13rem',
    input_width = '18rem',
    required = false,
    message,
    checked,
    pattern,
    readOnly,
    label_style = {},
    input_style = {},
    div_style = {} }) => {
    return (
        <div className='D_B_InputWithLabel' style={div_style}>
            <label htmlFor={title} style={{ width: `${label_width}`, minWidth: `${label_width}`, ...label_style }} className='D_B_InputWithLabel_label'>{title}</label>
            <input
                type={type}
                id={title}
                value={value}
                checked={checked}
                onChange={on_change}
                autoComplete="off"
                style={{ width: input_width, ...input_style }}
                required={required}
                readOnly={readOnly}
                disabled={readOnly}
                pattern={pattern}
            />
            <span>{message}</span>
        </div>
    )
}

export default InputWithLabel