import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { MdClear } from 'react-icons/md'
import { VscListFilter } from 'react-icons/vsc'
import SelectionTabTitles from './SelectionTabTitles'
import SelectionTabField from './SelectionTabField'
import SelectionTabCompare from './SelectionTabCompare'
import SelectionTabValue from './SelectionTabValue'
import ControlButtons from './ControlButtons'
import SearchRulesView from './SearchRulesView'


const Searcher = ({ timeout = 500, fieldsarr = [], fullobj_s = false }) => {
    const fldtempl = fieldsarr.map((val) => ({
        name: val.name ?? val,
        method: val.method ?? '',
        value1: '',
        value2: '',
        options: val.options ? val.options : null,// Select box options
        type: val.type ?? 'text',
        compare: val.compare
    }));
    const entrytempl = { tab: 'field', field: '', compare: '', value1: '', value2: '' }
    const comparisons = useMemo(() => ({
        'text': [
            ['equals', 'not equals'],
            ['contains', 'not contains'],
            ['empty', 'not empty'],
            ['starts with', 'not starts with'],
            ['ends with', 'not ends with']
        ],
        'date': [
            ['equals', 'not equals'],
            ['empty', 'not empty'],
            ['between', 'not between'],
            ['less than', 'greater than'],
            ['less or equal', 'greater or equal']
        ],
        'datetime-local': [
            ['equals', 'not equals'],
            ['empty', 'not empty'],
            ['between', 'not between'],
            ['less than', 'greater than'],
            ['less or equal', 'greater or equal']
        ],
        'number': [
            ['equals', 'not equals'],
            ['empty', 'not empty'],
            ['between', 'not between'],
            ['less than', 'greater than'],
            ['less or equal', 'greater or equal']
        ]
    }), [])
    const [entry, setEntry] = useState(entrytempl)

    const [fieldsI, setFieldsI] = useState(fldtempl)
    const [rules, setRules] = useState([])
    const [join, setJoin] = useState('NONE')
    //fields = [{ name: 'Tel', selected: false }, { name: 'mob', selected: false }, { name: 'name', selected: false }], setFields
    const [query, setQuery] = useState('')
    const [_query, set_query] = useState('')
    const searchinput = useRef(null);
    const searchbox = useRef(null);
    const clearbtn = useRef(null);
    const fieldsel = useRef(null);
    const optionsbox = useRef(null);
    const [timer, setTimer] = useState(undefined);
    const [boxstage, setBoxstage] = useState(1)

    const handle_input = (value) => {
        if (join !== 'NONE') {
            setJoin('NONE')
            setFieldsI(fldtempl)
            setRules([])
        }
        set_query(value);
        clearTimeout(timer);
        if (setQuery) setTimer(setTimeout(() => setQuery(value.toLowerCase()), timeout))
    }

    useEffect(() => {
        return () => clearTimeout(timer);
    }, [timer])

    // validate() Exporter Support Functions
    const convert = useCallback((val, type) => {
        if (!val || typeof val === 'object' || Array.isArray(val)) {
            return val
        } else if (type === 'text') {
            return val.toString().toLowerCase()
        } else if (type === 'date') {
            return val.toString().split('T')[0]
        } else if (type === 'number') {
            return parseInt(val) ? parseInt(val) : 0
        } else {
            return val
        }
    }, [])
    const searchobj = useCallback((obj) => {
        if (obj && typeof obj === 'object') {
            return Object.values(obj).find(val => {
                if (typeof val === 'object' || Array.isArray(val)) {
                    return searchobj(val)
                } else {
                    return val.toString().toLowerCase().includes(query)
                }
            })
        } else if (Array.isArray(obj)) {
            obj.find(val => {
                if (typeof val === 'object' || Array.isArray(val)) {
                    return searchobj(val)
                } else {
                    return val.toString().toLowerCase().includes(query)
                }
            })
        }
        if (obj) return obj.toString().toLowerCase().includes(query)
    }, [query])
    // validate() exporter function
    //<Validate Given Object for given rule(not selected rule)>
    const validateEx = useCallback((obj, rules) => {
        let val, val1, val2;
        let cValid = [];
        if (!Array.isArray(rules) || rules.length === 0) {
            return true
        }
        cValid = rules.filter(rule => {
            val = rule.method(obj) ?? ''
            if (!rule.compare.includes('empty')) {
                val1 = convert(rule.value1, rule.type) ?? null
                val2 = rule.value2 ? convert(rule.value2, rule.type) ?? null : null
            }
            if (Array.isArray(val)) {
                val = val.map(lval => convert(lval, rule.type))
            } else if (typeof val === 'object') {
                return false
            } else {
                val = [convert(val, rule.type)]
            }
            switch (rule.compare) {
                case 'equals':
                    return val.find(lval => lval === val1)
                case 'not equals':
                    return val.find(lval => lval !== val1)
                case 'contains':
                    return val.find(lval => lval?.includes(val1))
                case 'not contains':
                    return !val.find(lval => lval?.includes(val1))
                case 'empty':
                    return val.length === 0 || (val.length === 1 && !val[0])
                case 'not empty':
                    return (val.length > 1 || (val.length === 1 && val[0]))
                case 'starts with':
                    return val.find(lval => lval?.startsWith(val1))
                case 'not starts with':
                    return !val.find(lval => lval?.startsWith(val1))
                case 'ends with':
                    return val.find(lval => lval?.endsWith(val1))
                case 'not ends with':
                    return !val.find(lval => lval?.endsWith(val1))
                case 'less than':
                    return val.find(lval => lval < val1)
                case 'greater than':
                    return val.find(lval => lval > val1)
                case 'less or equal':
                    return val.find(lval => lval <= val1)
                case 'greater or equal':
                    return val.find(lval => lval >= val1)
                case 'between':
                    return val.find(lval => lval >= val1 && lval <= val2)
                case 'not between':
                    return !val.find(lval => lval >= val1 && lval <= val2)
                default:
                    return false;
            }
        })
        return cValid.length === rules.length
    }, [convert])
    // </Validate Given Object for given rule(not selected rule)> *

    const validate = useCallback((obj) => {
        let val, val1, val2;
        let cValid = [];
        if ((!join || join === 'NONE')) {
            if (!query) {
                return true
            } else {
                cValid = fieldsI.filter(field => {
                    if (field?.method && !field.searchhide) {
                        val = field.method(obj)?.toString() ?? ''
                        val1 = query
                        if (Array.isArray(val)) {
                            if (val.filter(lval => lval && lval.toString().toLowerCase().includes(val1)).length > 0) return true
                        } else {
                            return (val && val1 && val.toLowerCase().includes(val1))
                        }
                    }
                    return false
                })
                if (cValid.length > 0 && fieldsI.length > 0) return true
                if ((fullobj_s || fieldsI.length === 0) && obj && (typeof obj) === 'object' && searchobj(obj)) return true
                return false
            }
        } else if (rules.length === 0) {
            return true
        }
        cValid = rules.filter(rule => {
            val = rule.method(obj) ?? ''
            if (!rule.compare.includes('empty')) {
                val1 = convert(rule.value1, rule.type) ?? null
                val2 = rule.value2 ? convert(rule.value2, rule.type) ?? null : null
            }
            if (Array.isArray(val)) {
                val = val.map(lval => convert(lval, rule.type))
            } else if (typeof val === 'object') {
                return false
            } else {
                val = [convert(val, rule.type)]
            }
            switch (rule.compare) {
                case 'equals':
                    return val.find(lval => lval === val1)
                case 'not equals':
                    return val.find(lval => lval !== val1)
                case 'contains':
                    return val.find(lval => lval?.includes(val1))
                case 'not contains':
                    return !val.find(lval => lval?.includes(val1))
                case 'empty':
                    return val.length === 0 || (val.length === 1 && !val[0])
                case 'not empty':
                    return (val.length > 1 || (val.length === 1 && val[0]))
                case 'starts with':
                    return val.find(lval => lval?.startsWith(val1))
                case 'not starts with':
                    return !val.find(lval => lval?.startsWith(val1))
                case 'ends with':
                    return val.find(lval => lval?.endsWith(val1))
                case 'not ends with':
                    return !val.find(lval => lval?.endsWith(val1))
                case 'less than':
                    return val.find(lval => lval < val1)
                case 'greater than':
                    return val.find(lval => lval > val1)
                case 'less or equal':
                    return val.find(lval => lval <= val1)
                case 'greater or equal':
                    return val.find(lval => lval >= val1)
                case 'between':
                    return val.find(lval => lval >= val1 && lval <= val2)
                case 'not between':
                    return !val.find(lval => lval >= val1 && lval <= val2)
                default:
                    return false;
            }
        })
        if (join === 'ALL') {
            return cValid.length === rules.length
        } else {
            return cValid.length > 0
        }
    }, [convert, fieldsI, fullobj_s, join, query, rules, searchobj])

    // Search box Content to be exported
    const content = (
        <div
            ref={searchbox}
            className="searcher_main"
            id='searcher_main'
            style={{ width: _query || boxstage > 1 ? '20vw' : '0' }}
            onMouseDown={(e) => {
                if ((e.ctrlKey || e.metaKey) && fieldsI?.length) {
                    setBoxstage(stage => stage === 3 ? 2 : 3)
                } else {
                    setBoxstage(stage => stage === 3 ? 3 : 2)
                }
            }}
            onClick={(e) => {
                e.stopPropagation()
                searchinput.current.focus()
            }}
            onBlur={(e) => {
                if (!e.currentTarget.contains(e.relatedTarget)) setBoxstage(stage => stage === 3 ? 3 : _query ? 2 : 1)
            }}
            tabIndex={2}
            onKeyDownCapture={(e) => {
                if (e.key === 'Escape') {
                    e.stopPropagation()
                    if (boxstage === 3 || _query) {
                        setBoxstage(2)
                    } else {
                        setBoxstage(1)
                    }
                }
            }}
        >
            <div ><AiOutlineSearch className="searcher_icon" /></div>
            <input
                ref={searchinput}
                type="text"
                value={_query}
                onChange={(e) =>
                    handle_input(e.target.value)}
                className="search_button_input"
                // onInput={() => searchinput.current.style.width = `${searchinput.current.scrollWidth}px`}
                // onInput={() => {searchinput.current.style.width = '20vw'; searchinput.current.style.padding = '0.5rem';}}
                // style={{ width: _query || boxstage > 1 ? '20vw' : '0', padding: _query || boxstage > 1 ? '0.5rem' : '0' }}
                style={{ width: _query || boxstage > 1 ? '100%' : '0' }}
                readOnly={boxstage !== 2}
                onKeyDown={(e) => {
                    if (e.key === 'ArrowDown' && fieldsI?.length) {
                        setBoxstage(3)
                    }
                }}
            />
            {_query && <div /* Clear Button */
                className='searcher_clear_btn'
                ref={clearbtn}
                onClick={() => { setJoin('NONE'); handle_input(''); searchinput?.current?.focus() }}>
                <MdClear />
            </div>}
            {fieldsI?.length > 0 &&
                <div /* Advanced Search Button */
                    ref={fieldsel}
                    className='searcher_advance_btn'
                    style={{ display: boxstage > 1 ? 'flex' : 'none' }}
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                    onMouseDown={(e) => {
                        e.stopPropagation()
                        setBoxstage(stage => stage === 3 ? 2 : 3)
                    }}><VscListFilter />
                </div>
            }
            <div /* Advanced Search Options Box */
                ref={optionsbox}
                className='searcher_advance_box'
                style={{ display: boxstage === 3 ? 'block' : 'none' }}
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
            >
                <div className='searcher_rel'>
                    <div className='searcher_adv_tabs_cont'>
                        <SelectionTabTitles entry={entry} setEntry={setEntry} />
                        <div className="searcher_adv_items_picker"
                        >
                            {/* Fields Selection Tab */}
                            <SelectionTabField fieldsI={fieldsI} entry={entry} setEntry={setEntry} />
                            {/* compare selection tab */}
                            <SelectionTabCompare fieldsI={fieldsI} comparisons={comparisons} entry={entry} setEntry={setEntry} setRules={setRules} entrytempl={entrytempl} />
                            {/* Value Entry tab */}
                            <SelectionTabValue fieldsI={fieldsI} entry={entry} setEntry={setEntry} optionsbox={optionsbox} setFieldsI={setFieldsI} fldtempl={fldtempl}
                                addRule={() => {
                                    if (entry.compare?.includes('empty') || entry.value1) {
                                        setRules(lrules => ([...lrules, {
                                            name: entry.field.name,
                                            method: entry.field.method,
                                            compare: entry.compare,
                                            value1: entry.value1,
                                            value2: entry.value2,
                                            type: entry.field.type,
                                        }]))
                                        setEntry(entrytempl)
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {/* Search Control Buttons */}
                    <SearchRulesView rules={rules} setRules={setRules} />
                    <ControlButtons join={join} rules={rules}
                        setAll={() => {
                            setJoin('ALL');
                            set_query('[ALL]')
                            setQuery('[ALL]')
                            setBoxstage(2)
                        }}
                        setAny={() => {
                            setJoin('ANY');
                            set_query('[ANY]')
                            setQuery('[ANY]')
                            setBoxstage(2)
                        }}
                        clear={() => {
                            setJoin('NONE')
                            setFieldsI(fldtempl)
                            setEntry(entrytempl)
                            setRules([])
                            set_query('')
                            setQuery('')
                            setBoxstage(2)
                        }}
                    />
                </div>
            </div>
        </div >
    )
    return { SearchBox: content, query, join, rules, validate, validateEx }
}

export default Searcher

/*
// Useage:
// 1. Import Searcher
import Searcher from "../common/Searcher/Searcher";

// 2. Define searcher as below. If Fieldsarr is given it is advanced search. otherwise its simple search.
  const searcher = Searcher({
    fieldsarr: [
      { name: 'Name', method: ({ name }) => name },
      { name: 'Address', method: ({ address }) => address },
      { name: 'Country', method: ({ country }) => country },
      { name: 'State', method: ({ state }) => state },
      { name: 'District', method: ({ district }) => district },
      { name: 'Mobile', method: ({ mobile }) => mobile },
      { name: 'Contact', method: ({ contact }) => contact },
      { name: 'Email', method: ({ email }) => email },
      { name: 'Designation', method: ({ designation }) => designation },
      { name: 'Status', method: ({ active }) => active ? 'Active' : 'Disabled', options: [{ value: 'Active', label: 'Active' }, { value: 'Disabled', label: 'Disabled' }], compare: [['equals']] },
      { name: 'Product Name', method: ({ products }) => products.map(prod => prod.name) },
      { name: 'Serial Number', method: ({ products }) => products.map(prod => prod.serialnumber) },
      { name: 'Prod.Expiry', method: ({ products }) => products.map(prod => prod.TSSExpiry), type: 'date' },
    ]
  })
// 3. Add search box to your form
const content = searcher.SearchBox

// 4. Apply filter to the array using searcher.validate(obj) method
const tableContent = ids?.length && ids.filter(id => searcher.validate(customers?.entities[id])).map(customerId =>
    <CustomerRow key={customerId} customerId={customerId} handleView={handleView} handleEdit={handleEdit} />)
*/