import { createContext, useCallback, useEffect, useRef, useState } from "react";
import useFacilitiesList from '../../../hooks/useFacilitiesList';
import { useGetProfileQuery, useGetUsersQuery, useReconfirmUserMutation, useSetUserConfigMutation } from "../../../features/usersApiSlice";
import { useGetCustomersQuery } from "../../../features/customersApiSlice";
import { useGetProductsQuery } from "../../../features/productsApiSlice";
import { Outlet } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useGetCallsQuery } from "../../../features/serviceCallApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { setsettings } from "../../../features/globalSlice";
const GC = createContext()
/* <WebSocket> */
const WSS_URL = process.env.REACT_APP_WSS_URL
let socket = new WebSocket(WSS_URL);

socket.onclose = (event) => {
    clearInterval(socket.timer)
    console.log('closed');
    setTimeout(() => {
        let lsocket = new WebSocket(WSS_URL);
        lsocket.onopen = socket.onopen
        lsocket.onclose = socket.onclose
        lsocket.onmessage = socket.onmessage
        socket = lsocket
    }, 3000)
}

socket.onopen = event => {
    console.log('opened');
    socket.timer = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) socket.send(JSON.stringify({ status: 'heartbeat' }))
    }, 300000)
}
/* </WebSocket> */

export const GCProvider = () => {
    // console.log('Rendering : GC');
    const isOnline = useRef(true)
    const [callNoChg, setCallNoChg] = useState(1)
    const dispatch = useDispatch()

    const users = useGetUsersQuery('usersList', {
        /* pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true, */
        selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => ({
            data,
            selectOptions: data?.ids?.filter(lid => data?.entities?.[lid]?.active)?.map(id => ({ value: id, label: data?.entities[id].username })),
            isLoading,
            isSuccess,
            isError,
            error
        }),
    })
    const customers = useGetCustomersQuery('customersList', {
        /* pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true, */
        selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => ({
            data,
            selectOptions: data?.ids?.filter(id => data?.entities[id]?.active)?.map(id => ({ value: id, label: data?.entities[id].name })),
            isLoading,
            isSuccess,
            isError,
            error
        }),
    })


    const products = useGetProductsQuery('productsList', {
        /* pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true, */
        selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => ({
            data,
            selectOptions: data?.ids?.filter(id => data?.entities[id]?.active)?.map(id => ({ value: id, label: data?.entities[id].name })),
            isLoading,
            isSuccess,
            isError,
            error
        }),
    })


    const calls = useGetCallsQuery('callsList', {
        /* pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true */
    })


    const { userid } = useAuth();
    const profile = useGetProfileQuery('userProfile', {
        selectFromResult: ({ data, isLoading, isError, error }) => ({
            data: data?.entities[userid],
            isLoading,
            isError,
            error
        }),
    })
    const thisusera = users?.data?.entities[userid]
    const thisuser = { ...thisusera, hasanycallright: (thisusera?.assign || thisusera?.assignRevert || thisusera?.reassignAssigned || thisusera?.reassignAttended || thisusera?.reassignDisposed || thisusera?.attend || thisusera?.attendRevert || thisusera?.disposeCreated || thisusera?.disposeAssigned || thisusera?.disposeAttended || thisusera?.disposeRevert) }
    /* if (thisuser.renewalcfg) {
        dispatch(setrenewalcfg({
            ...renewalcfg,
            TSSExpired: thisuser.renewalcfg?.TSSExpired ?? true,
            TSSExpiring: thisuser.renewalcfg?.TSSExpiring ?? true,
            AMCExpired: thisuser.renewalcfg?.AMCExpired ?? true,
            AMCExpiring: thisuser.renewalcfg?.AMCExpiring ?? true,
        }))
    } */

    /* <InstallUserSettings> */
    const settings = useSelector(state => state.global.settings)
    useEffect(() => {
        if (!settings.dbloaded && thisuser?.settings) {
            const settings_db = JSON.parse(thisuser.settings)
            if (settings_db?.fontSize && settings_db?.fontSize !== settings.fontSize) {
                dispatch(setsettings({ ...settings, dbloaded: true, fontSize: settings_db?.fontSize }))
                document.documentElement.style.fontSize = `${settings_db?.fontSize}px`
            }
        }
    }, [thisuser?.settings, settings, dispatch])

    /* </InstallUserSettings> */


    const FL = useFacilitiesList();





    const datetime_local = useCallback((date) => {
        return (date instanceof Date && date.getFullYear()) ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}` : ''
    }, [])
    const datetime_localstr = useCallback((date) => {
        return (date instanceof Date && date.getFullYear()) ? `${date.getDate().toString().padStart(2, '0')} ${date.toLocaleDateString('en-UK', { month: 'long' }).substr(0, 3)} ${date.getFullYear()}  ${date.toLocaleTimeString()}` : ''
    }, [])
    const date_localstr = useCallback((date) => {
        return (date instanceof Date && date.getFullYear()) ? `${date.getDate().toString().padStart(2, '0')} ${date.toLocaleDateString('en-UK', { month: 'long' }).substr(0, 3)} ${date.getFullYear()}` : ''
    }, [])

    const dateTostr = useCallback((date) => {
        return (date instanceof Date) ? `${date.getFullYear()}${date.getMonth().toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')} ` : ''
    }, [])
    const strToDate = useCallback((str) => {
        return (str) ? new Date(parseInt(str.substring(0, 4)), parseInt(str.substring(4, 6)) - 1, parseInt(str.substring(6, 8))) : str
    }, [])

    const userconfig = useSetUserConfigMutation()
    const reconfirmUser = useReconfirmUserMutation()

    // <SocketHandle>
    const customersrepcfg = useSelector(state => state.global.customersrepcfg)
    socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (Array.isArray(data) && data.length > 0) {
            const refetchSet = new Set()
            data.map(item => {
                refetchSet.add(item.type)
                if (item.type === 'call' && item.action === 'create') setCallNoChg(no => no + 1)
                return true
            })
            refetchSet.forEach(item => {
                if (item === 'product') {
                    products.refetch()
                } else if (item === 'customer') {
                    if (customersrepcfg.batching === 0) {
                        customers.refetch()
                    }
                } else if (item === 'user') {
                    users.refetch()
                } else if (item === 'call') {
                    calls.refetch()
                } else if (item === 'settings') {
                    settings.refetch()
                }
            })
            socket.send(JSON.stringify({ status: 'ack' }))
        } else {
        }
    }
    useEffect(() => {
        if (!isOnline.current && navigator.onLine) {
            isOnline.current = true
            socket.send(JSON.stringify({ status: 'has refetch?' }))
        } else if (isOnline.current && !navigator.onLine) {
            isOnline.current = false
        }
    }, [isOnline])
    // </SocketHandle>

    return (
        <GC.Provider value={{
            FL, users, products, customers, profile, thisuser, calls,
            datetime_local, datetime_localstr, date_localstr, dateTostr, strToDate,
            callNoChg,
            userconfig,
            reconfirmUser
        }}>
            <Outlet />
        </GC.Provider>
    )

}

export default GC